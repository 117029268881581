import React, { Component } from "react";
import moment from "moment";
import swal from "sweetalert2";
import api from "../../helpers/api"
import { compressImage } from "../../helpers/imagesCompress";

export default class FormCambio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      texto: "",
      nom_senal: "",
      desde: moment().format("YYYY-MM-DD H:mm:ss"),
      status: "pendiente",
      user_id: sessionStorage.getItem("user") || 1,
      instalacion: moment().format("YYY-MM-DD H:mm:ss"),
      images: "",
      descripcion: "",
      senal_data: {
        cod_nuevo: "",
        cod_man: "",
        idsenal: "",
        nom_senal: "",
        classenal: "",
        principal: "",
        sec_1: "",
        sec_2: "",
        num: "",
        medidas: "",
        texto: "",
        ubi: "",
        ori: "",
        x_coord: "",
        y_coord: "",
        images: "",
        cuadrante: "",
      },

      // AUTOCOMPLETE DATA
      ac_textos: {},
      ac_tipos: {},
      // types
      types: []
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    api.get("/signals/autocompletes")
      .then((res) => {
        const { tipos, textos } = res.data;

        this.setState({
          ac_tipos: tipos,
          ac_textos: textos,
        });

        window.$("input#man_nom_senal").autocomplete({
          data: this.state.ac_tipos,
        });

        window.$("input#man_texto").autocomplete({
          data: this.state.ac_textos,
        });
      })
      .catch((err) => console.error(err));
    
    api.get('/mantenciones/types')
      .then(res => {
        this.setState({
          types: res.data.tipos_mantencion
        })
      })
    window.$("select").formSelect();
  }

  handleSubmitForm = async (event) => {
    event.preventDefault();

    const data = new FormData();

    data.append("texto", document.getElementById("man_texto").value);
    data.append("status", this.state.status);
    data.append("desde", moment().format("YYYY-MM-DD H:mm:ss"));
    data.append("user_id", this.state.user_id);
    data.append("type", "MANTENCION");
    data.append(
      "descripcion",
      document.getElementById("man_descripcion").value
    );
    data.append("instalacion", moment().format("YYYY-MM-DD H:mm:ss"));

    let senal = JSON.stringify(this.props.senal);

    data.append("senal", senal);

    for (let i = 0; i < this.state.images.length; i++) {
      const imageCompressed = await compressImage(this.state.images[i])
        data.append("images", imageCompressed);
    }

    api.post("/mantencion", data, {})
      .then((res) => {
        swal.fire({
          title: "Mantención agregada",
          icon: "success",
        });
        this.form.current.reset();
        // this.props.update();

        window.$("#modalNewMantencion").modal("close");
      })
      .catch((err) => {
        console.log(err)
        swal.fire({
          title: "Error inesperado",
          icon: "error",
        });
      });
  };

  render() {
    const { senal } = this.props;
    const texto = senal ? senal.texto : null;

    return (
      <div className="row">
        <div className="col s12">
          <div className="card">
            <form
              className="col s12 "
              style={{ marginTop: "20px" }}
              onSubmit={(event) => this.handleSubmitForm(event)}
              ref={this.form}
            >
              <div className="card-content">
                <h3 className="card-title">Nueva Mantención</h3>
                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="man_texto"
                      type="hidden"
                      autoComplete="off"
                      onChange={(e) => this.setState({ texto: e.target.value })}
                      defaultValue={texto}
                    />
                    {/* <label htmlFor="man_texto">Texto</label> */}
                  </div>
                </div>

                <div className="row">
                  <div className="col s12">
                    <select required id="man_descripcion" className="browser-default">
                      {this.state.types.map((type, index) => <option value={type} key={index}>{type}</option>)}
                    </select>

                    <label htmlFor="man_descripcion">
                      Descripcion de la mantención
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="file-field input-field mb-4 col s12">
                    <div className="btn btn-sm">
                      <span>Fotos</span>
                      <input
                        id="man_images"
                        name="images"
                        type="file"
                        multiple
                        onChange={(e) =>
                          this.setState({ images: e.target.files })
                        }
                        required
                      />
                    </div>

                    <div className="file-path-wrapper">
                      <input
                        className="file-path validate"
                        type="text"
                        placeholder="Puedes ingresar mas de una foto..."
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-action mb-4">
                <button className="btn btn-small float-right" type="submit">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
