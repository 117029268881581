import React, { Component } from "react";

import "./App.css";
import Routes from "./routes/Routes";
//import Header from "./components/layout/Header";
// import Sidenav from "./components/layout/Sidenav";

class App extends Component {
  render() {
    return (
      <div>
       {/*  <Header /> */}
        {/* <Sidenav /> */}
        {/* <div>*/}
          <Routes></Routes>
        {/* </div>*/}
      </div>
    );
  }
}

export default App;
