import jwt from "jwt-simple";
const SECRET_TOKEN = "cl.transurbe.senals.signals.back";

function getToken() {
  let token = window.sessionStorage.getItem("_token");

  return token;
}

async function setToken(token) {
  window.sessionStorage.setItem("_token", token);

  return true;
}

export async function getData() {
  const token = window.sessionStorage.getItem("_token");
  if (!token) {
    return false;
  }
  let data = await jwt.decode(token, SECRET_TOKEN);
  return data;
}

async function removeToken() {
  window.sessionStorage.removeItem("_token");
}

export const hasPermission = async (permission) => {
  const data = await getData();

  const res = data ? data.permisos.includes(permission) : false;

  return res;
}

export default { getToken, setToken, getData, removeToken, hasPermission };
