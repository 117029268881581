import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// pages
import Home from "../pages/Home";
import Login from "../pages/Login";

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (sessionStorage.getItem("_token") === null) {
        return <Component {...props} />;
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>

        <LoginRoute path="/login" exact component={Login} />

        {/* <Route path="/nueva/solicitud" exact>
          <NewSolicitud />
        </Route> */}
      </Switch>
    );
  }
}
