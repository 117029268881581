import React, { Component } from "react";
import moment from "moment";
import api from "../../helpers/api"
import swal from "sweetalert2";
import { compressImage } from "../../helpers/imagesCompress";

export default class FormCambio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cod_nuevo: "",
      cod_man: "",
      idsenal: "",
      nom_senal: "",
      classenal: "",
      medidas: "",
      texto: "",
      images: "",
      cuadrante: "",
      status: "pendiente",
      desde: moment().format("YYYY-MM-DD H:mm:ss"),
      user_id: sessionStorage.getItem("user") || 1,
      instalacion: moment().format("YYYY-MM-DD H:mm:ss"),

      // autocomplete data
      ac_tipos: {},
      ac_cod_nuevo: {},
      ac_cod_man: {},
      ac_clases: {},
      ac_textos: {},
      ac_medidas: {},
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    this.autocompletes();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.autocomplete !== this.props.autocomplete) {
      this.autocompletes();
      window.M.updateTextFields();
    }
  }
  autocompletes = async () => {
    window.$("input#cam_nom_senal").autocomplete({
      data: this.props.autocomplete.ac_tipos,
    });

    window.$("input#cam_texto").autocomplete({
      data: this.props.autocomplete.ac_textos,
    });

    window.$("input#cam_cod_nuevo").autocomplete({
      data: this.props.autocomplete.ac_cod_nuevo,
    });

    window.$("input#cam_cod_man").autocomplete({
      data: this.props.autocomplete.ac_cod_man,
    });

    window.$("input#cam_medidas").autocomplete({
      data: this.props.autocomplete.ac_medidas,
    });

    window.$("input#cam_classenal").autocomplete({
      data: this.props.autocomplete.ac_clases,
    });
  };

  handleSubmitForm = async (event) => {
    event.preventDefault();

    const data = new FormData();

    data.append("cod_nuevo", document.getElementById("cam_cod_nuevo").value);
    data.append("cod_man", document.getElementById("cam_cod_man").value);
    data.append("idsenal", document.getElementById("cam_idsenal").value);
    data.append("nom_senal", document.getElementById("cam_nom_senal").value);
    data.append("classenal", document.getElementById("cam_classenal").value);
    data.append("principal", this.props.senal.principal);
    data.append("sec_1", this.props.senal.sec_1);
    data.append("sec_2", this.props.senal.sec_2);
    data.append("num", this.props.senal.num);
    data.append("medidas", document.getElementById("cam_medidas").value);
    data.append("texto", document.getElementById("cam_texto").value);
    data.append("ubi", this.props.senal.ubi);
    data.append("ori", this.props.senal.ori);
    data.append("x_coord", this.props.senal.x_coord);
    data.append("y_coord", this.props.senal.y_coord);
    data.append("cuadrante", document.getElementById("cam_cuadrante").value);
    data.append("status", this.state.status);
    data.append("desde", moment().format("YYYY-MM-DD H:mm:ss"));
    data.append("instalacion", moment().format("YYYY-MM-DD H:mm:ss"));

    let senal = JSON.stringify(this.props.senal);
    data.append("senal", senal);

    data.append("user_id", this.state.user_id);
    data.append("type", "CAMBIO");
    data.append(
      "descripcion",
      document.getElementById("cam_descripcion").value
    );

    for (let i = 0; i < this.state.images.length; i++) {
      const imageCompressed = await compressImage(this.state.images[i])
      data.append("images", imageCompressed);
    }

    api.post("/cambio", data)
      .then((res) => {
        swal.fire({
          title: "Cambio de señal registrado",
          icon: "success",
        });

        window.$("#modalNewCambio").modal("close");
      })
      .catch((err) => {
        swal.fire({
          title: "Error inesperado",
          icon: "error",
        });
      });
  };

  render() {
    const { senal } = this.props;
    console.log("FormCambio -> render -> senal", senal)
    
    return (
      <div className="row">
        <div className="col s12">
          <div className="card">
            <form
              className="col s12 "
              style={{ marginTop: "20px" }}
              onSubmit={(event) => this.handleSubmitForm(event)}
              ref={this.form}
            >
              <div className="card-content">
                <h3 className="card-title">Cambio de Señal</h3>

                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="cam_nom_senal"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ nom_senal: e.target.value })
                      }
                      required
                      defaultValue={senal ? senal.nom_senal : null}
                    />
                    <label htmlFor="cam_nom_senal">Tipo</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="cam_texto"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => this.setState({ texto: e.target.value })}
                      defaultValue={senal ? senal.texto : null}
                    />
                    <label htmlFor="cam_texto">Texto</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      id="cam_idsenal"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ idsenal: e.target.value })
                      }
                      required
                    />
                    <label htmlFor="cam_idsenal">ID</label>
                  </div>

                  <div className="input-field col s12 m6">
                    <input
                      id="cam_cuadrante"
                      type="number"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ cuadrante: e.target.value })
                      }
                      required
                      defaultValue={senal ? senal.cuadrante : null}
                    />
                    <label htmlFor="cam_cuadrante">Sector</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      id="cam_cod_nuevo"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ cod_nuevo: e.target.value })
                      }
                      required
                      defaultValue={senal ? senal.cod_nuevo : null}
                    />
                    <label htmlFor="cam_cod_nuevo">Cod. Nuevo</label>
                  </div>

                  <div className="input-field col s12 m6" style={{display: 'none'}}>
                    <input
                      id="cam_cod_man"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ cod_man: e.target.value })
                      }
                      disabled={true}
                      required
                      defaultValue={senal ? senal.cod_man : null}
                    />
                    <label htmlFor="cam_cod_man">Cod. Man</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      type="text"
                      id="cam_medidas"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ medidas: e.target.value })
                      }
                      defaultValue={senal ? senal.medidas : null}
                    />
                    <label htmlFor="cam_medidas">Medidas</label>
                  </div>

                  <div className="input-field col s12 m6">
                    <input
                      type="text"
                      id="cam_classenal"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ classenal: e.target.value })
                      }
                      defaultValue={senal ? senal.classenal : null}
                    />
                    <label htmlFor="cam_classenal">Clase</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    <textarea
                      id="cam_descripcion"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ descripcion: e.target.value })
                      }
                      required
                      rows="5"
                      className="materialize-textarea"
                    />

                    <label htmlFor="man_descripcion">
                      Descripcion del cambio
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="file-field input-field mb-4 col s12">
                    <div className="btn btn-sm">
                      <span>Fotos</span>
                      <input
                        id="cam_images"
                        name="images"
                        type="file"
                        multiple
                        onChange={(e) =>
                          this.setState({ images: e.target.files })
                        }
                        required
                      />
                    </div>

                    <div className="file-path-wrapper">
                      <input
                        className="file-path validate"
                        type="text"
                        placeholder="Puedes ingresar mas de una foto..."
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-action mb-4">
                <button className="btn btn-small float-right" type="submit">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
