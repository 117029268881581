import React, { Component } from "react";
import auth from "../../helpers/auth";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined,
    };
  }

  async componentDidMount() {
    window.$(".profile-button").dropdown({
      inDuration: 300,
      outDuration: 225,
      constrainWidth: false,
      hover: false,
      gutter: 0,
      coverTrigger: false,
      alignment: "right",
      // stopPropagation: false
    });

    let data = await auth.getData();
    this.setState({ user: data });
  }

  logout = async () => {
    await auth.removeToken();

    window.location.href = "/";
  };
  
  render() {
    
    
    return (
      <header className="page-topbar" id="header">
        <div className="navbar navbar-fixed">
          <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-light-blue-cyan">
            <div className="nav-wrapper">
              <ul className="left">
                <li>
                  <h1 className="logo-wrapper">
                    <a className="brand-logo darken-1" href="index.html">
                      {/* <img
                        src="../../../app-assets/images/logo/materialize-logo.png"
                        alt="materialize logo"
                      /> */}
                      <span className="logo-text hide-on-med-and-down">
                        Transurbe - Señales de transito de Las Condes
                      </span>
                    </a>
                  </h1>
                </li>
              </ul>
              {/*<ul className="right">
                {
                this.state.user ? (<div>{this.state.user.nombre}</div>) : ("")
                }
              </ul>   */ } 
              <ul className="navbar-list right">
                {
                /* <li>
                  <a
                    className="waves-effect waves-block waves-light notification-button"
                    href="#!"
                    data-target="#"
                  >
                    <i className="material-icons">
                      notifications_none
                      <small className="notification-badge">5</small>
                    </i>
                  </a>
                  <ul
                    className="dropdown-content"
                    id="notifications-dropdown"
                    tabIndex={0}
                  >
                    <li tabIndex={0}>
                      <h6>
                        NOTIFICATIONS<span className="new badge">5</span>
                      </h6>
                    </li>
                    <li className="divider" tabIndex={0} />
                    <li tabIndex={0}>
                      <a className="grey-text text-darken-2" href="#!">
                        <span className="material-icons icon-bg-circle cyan small">
                          add_shopping_cart
                        </span>
                        A new order has been placed!
                      </a>
                      <time
                        className="media-meta"
                        dateTime="2015-06-12T20:50:48+08:00"
                      >
                        2 hours ago
                      </time>
                    </li>
                    <li tabIndex={0}>
                      <a className="grey-text text-darken-2" href="#!">
                        <span className="material-icons icon-bg-circle red small">
                          stars
                        </span>
                        Completed the task
                      </a>
                      <time
                        className="media-meta"
                        dateTime="2015-06-12T20:50:48+08:00"
                      >
                        3 days ago
                      </time>
                    </li>
                    <li tabIndex={0}>
                      <a className="grey-text text-darken-2" href="#!">
                        <span className="material-icons icon-bg-circle teal small">
                          settings
                        </span>
                        Settings updated
                      </a>
                      <time
                        className="media-meta"
                        dateTime="2015-06-12T20:50:48+08:00"
                      >
                        4 days ago
                      </time>
                    </li>
                    <li tabIndex={0}>
                      <a className="grey-text text-darken-2" href="#!">
                        <span className="material-icons icon-bg-circle deep-orange small">
                          today
                        </span>
                        Director meeting started
                      </a>
                      <time
                        className="media-meta"
                        dateTime="2015-06-12T20:50:48+08:00"
                      >
                        6 days ago
                      </time>
                    </li>
                    <li tabIndex={0}>
                      <a className="grey-text text-darken-2" href="#!">
                        <span className="material-icons icon-bg-circle amber small">
                          trending_up
                        </span>
                        Generate monthly report
                      </a>
                      <time
                        className="media-meta"
                        dateTime="2015-06-12T20:50:48+08:00"
                      >
                        1 week ago
                      </time>
                    </li>
                  </ul>
                </li>*/}
                
                <li>
                  <a
                    className="waves-effect waves-block waves-light profile-button"
                    href="#!"
                    data-target="profile-dropdown"
                  >
                    <span >{this.state.user ? (<span>{this.state.user.nombre}   </span>) : ("")}</span>
                    <span className="avatar-status avatar-online">
                      <span className="material-icons">person</span>
                    </span>
                  </a>
                  <ul
                    className="dropdown-content"
                    id="profile-dropdown"
                    tabIndex={0}
                  >
                    {!this.state.user ? (
                      <li tabIndex={0}>
                        <a className="grey-text text-darken-1" href="/login">
                          <i className="material-icons">lock_outline</i> Login
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.user ? (
                      <li tabIndex={0}>
                        <a
                          className="grey-text text-darken-1"
                          href="#!"
                          onClick={() => this.logout()}
                        >
                          <i className="material-icons">keyboard_tab</i> Logout
                        </a>
                      </li>
                      ) : ("")
                    }
                  </ul>
                </li>
              </ul>
              
              {/* translation-button*/}
              {/* notifications-dropdown*/}
              {/* profile-dropdown*/}
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
