import axios from "axios";
import auth from "./auth";

const token = auth.getToken();

const api = axios.create({
    baseURL: '/api',
    headers: {
        Authorization: `Bearer ${token}`,
        'X-Requested-With': 'XMLHttpRequest',
        ContentType: 'application/json',
    },
})


export default api;