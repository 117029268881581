import React from 'react';
import * as moment from 'moment';
const PopUp = ({
  data,
  newSolicitud,
  solcitudes,
  openDropdown,
  newMantencion,
  historyActions,
  newCambio,
  newMover,
  user,
  remove,
  historyMantenciones
}) => {
  window.$('.dropdown-trigger').dropdown();
  let image = '/default-image.png';
  if(data.foto) {
    const hasImge = data.foto.split('/static2/')
    image  = hasImge.length > 1 ? data.foto : '/default-image.png' 
  }

  const canDoActions = {
    mantenciones: data.status === 'instalada',
    actions: !data.pendings && data.status === 'instalada'
  }
  
  return (
    <div style={{ minWidth: '250px' }}>
      <div className="mb-2 mt-1 center-align">
      
        {data.foto ? 
          <img src={image} alt="Foto señal" width={150} height={100}/>
          : ''
        }
      </div>
      <div className="mb-4">
        <b>ID: </b> {data.idsenal} <b className="ml-2">Sector: </b>{' '}
        {data.cuadrante}
      </div>
      <div className="mb-4">
        <b>Tipo: </b> {data.nom_senal}
      </div>
      <div className="mb-4">
        <b>Texto: </b> {data.texto}
      </div>
      <div className="mb-4">
        <b>Calle P: </b> {data.principal}
      </div>
      <div className="mb-4">
        <b>N° Calle: </b> {data.num}
      </div>
      <div className="mb-4">
        <b>Calle Sec 1: </b> {data.sec_1}
      </div>
      <div className="mb-4">
        <b>Calle Sec 2: </b> {data.sec_2}
      </div>
      <div className="mb-4">
        <b>Instalacion: </b>
        {data.instalacion ? moment(data.instalacion).format('YYYY/MM/DD') : null}
      </div>
      <div className="">
        <button
          className="waves-effect waves-light btn-small btn-floating mr-2 dropdown-trigger"
          data-target="solicitudes"
          title="Solicitudes"
          onClick={() => openDropdown('btn-sol')}
          id="btn-sol"
        >
          <i className="material-icons">announcement</i>
        </button>
        <ul className="dropdown-content" id="solicitudes">
          {user ? (
            user.permisos.includes('solicitudes_index') ? (
              <li>
                <a href="#!" onClick={() => solcitudes(data)}>
                  Ver
                </a>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {canDoActions.mantenciones ? 
            <li>
              <a href="#!" onClick={() => newSolicitud()}>
                Agregar
              </a>
            </li> 
            : ''
          }
        </ul>
        {!user ? (
          ''
        ) : (
          <button
            className="waves-effect waves-light btn-small btn-floating mr-2 dropdown-trigger"
            data-target="mantenciones"
            title="Mantenciones"
            onClick={() => openDropdown('btn-mant')}
            id="btn-mant"
          >
            <i className="material-icons">build</i>
          </button>
        )}
        <ul className="dropdown-content" id="mantenciones">
          {user && !user.permisos.includes('acciones_historial') ? (
            ''
          ) : (
            <li>
              <a href="#!" onClick={() => historyMantenciones(data.idsenal)}>
                Ver
              </a>
            </li>
          )}
          {user && !user.permisos.includes('senales_mantencion') ? (
            ''
          ) : canDoActions.mantenciones ? (
            <li>
              <a href="#!" onClick={() => newMantencion(data)}>
                Agregar
              </a>
            </li>
          ) 
          : ''}
        </ul>
        {user ? (
          user.permisos.includes('senales_cambiar') ? (
            <button
              className="waves-effect waves-light btn-small btn-floating mr-2"
              title="Cambiar señal"
              onClick={() => newCambio(data)}
              disabled={!canDoActions.actions}
            >
              <i className="material-icons">swap_horiz</i>
            </button>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {user ? (
          user.permisos.includes('senales_modificar') ? (
            <button
              className="waves-effect waves-light btn-small btn-floating mr-2"
              title="Editar señal"
              onClick={() => newMover(data)}
              disabled={!canDoActions.actions}
            >
              <i className="material-icons">edit</i>
            </button>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {user ? (
          user.permisos.includes('senales_modificar') ? (
              <button
                className="waves-effect waves-light btn-small btn-floating mr-2"
                title="Remover señal"
                onClick={() => remove(data)}
                disabled={!canDoActions.actions}
              >
                <i className="material-icons">delete</i>
              </button>
            ) : (
              ''
             )
          ) : (
            ''
        )}
        {user ? (
          user.permisos.includes('acciones_historial') ? (
            <button
              className="waves-effect waves-light btn-small btn-floating mr-2 mt-1"
              title="Historial Acciones"
              onClick={() => historyActions(data, null)}
            >
              <i className="material-icons">list</i>
            </button>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default PopUp