import React, { useState, useRef, useCallback, useEffect } from 'react'
import api from '../../helpers/api';
import Paper from "@material-ui/core/Paper";
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
  Plugin, Template, TemplatePlaceholder
} from '@devexpress/dx-react-core';
import {
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
  } from "@devexpress/dx-react-grid";
  import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    ExportPanel,
    TableFilterRow,
  } from "@devexpress/dx-react-grid-material-ui";
import {saveAs} from 'file-saver';
import * as moment from 'moment'
import swal from "sweetalert2";
import auth from '../../helpers/auth';


const HistoryActions = (props) => {
    const [rows, setRows] = useState([]);
    const [desde, setDesde] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    // const [sector, setSector] = useState('');
    const [idsenal, setIdsenal] = useState('');
    const [tipo, setTipo] = useState('');
    const [hasta, setHasta] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [filters, setFilters] = useState([]);

    const exporterRef = useRef(null);
    
    const [columns, setColumns] = useState([
      { name: "actions", title: "Acciones" },
      { name: "idsenal", title: "ID" },
      { name: "action_type", title: "Tipo" },
      { name: "action_approved", title: "Aprobado?" },
      { name: "cuadrante", title: "Sector" },
      { name: "cod_nuevo", title: "Codigo" },
      { name: "nom_senal", title: "Señal" },
      { name: "texto", title: "Texto" },
      { name: "principal", title: "Calle P" },
      { name: "num", title: "N° Calle" },
      { name: "sec_1", title: "Secundaria 1" },
      { name: "sec_2", title: "Secundaria 2" },
      { name: "fecha", title: "Fecha" },
      { name: "descripcion", title: "Acción" },
      { name: "changes", title: "Cambios" },
      { name: "user_name", title: "Responsable" },
    ]);

    const columnsExport = [
      { name: "idsenal", title: "ID" },
      { name: "action_type", title: "Tipo" },
      { name: "action_approved", title: "Aprobado?" },
      { name: "cuadrante", title: "Sector" },
      { name: "cod_nuevo", title: "Codigo" },
      { name: "nom_senal", title: "Señal" },
      { name: "texto", title: "Texto" },
      { name: "principal", title: "Calle P" },
      { name: "num", title: "N° Calle" },
      { name: "sec_1", title: "Secundaria 1" },
      { name: "sec_2", title: "Secundaria 2" },
      { name: "fecha", title: "Fecha" },
      { name: "descripcion", title: "Acción" },
      { name: "changes", title: "Cambios" },
      { name: "user_name", title: "Responsable" },
    ];

    const idsenalPredicate = (value, filter) => value == filter.value;

    const [integratedFilteringColumnExtensions] = useState([
      { columnName: 'idsenal', predicate: idsenalPredicate },
    ]);

    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `acciones-${desde}-${hasta}.xlsx`);
        });
    }
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
      }, [exporterRef]);

    const getHistoryActions = async (from = 'state') => {
      let h_desde  = desde; 
      let h_hasta = hasta;
      let h_tipo = tipo;
      let h_idsenal = idsenal;

      if(from === 'props') {
        h_tipo = props.tipo;
        h_idsenal = props.idsenal;
      }
      
      
      let query = `desde=${h_desde}&hasta=${h_hasta}&tipo=${h_tipo}&idsenal=${h_idsenal}`;
      const result = await api.get(`/actions/history?${query}`);

      const permission = await auth.hasPermission('acciones_aprobar');
      let data = result.data;
      if(permission) {
        data = result.data.map((row) => {
          console.log("getHistoryActions -> row.approved ", row.action_approved )
          if(row.action_approved === 'No' ) {
            row.actions = (
              <>
                <button
                  className="waves-effect waves-light btn-small btn-floating"
                  title="Aprobar"
                  onClick={() => approveAction(row.id)}
                >
                  <i className="material-icons">done</i>
                </button>
                <button
                  className="waves-effect waves-light btn-small btn-floating"
                  title="Eliminar"
                  onClick={() => deleteAction(row.id)}
                >
                  <i className="material-icons">close</i>
                </button>
              </>
            );
          }
          return row;
        }) 
      }


      setRows(data);
    }

    const approveAction = async (id) => {

      swal.fire({
        title: 'Quieres aprobar esta acción?',
        showCancelButton: true,
        confirmButtonText: `Si`,
        cancelButtonText: 'No'
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if(!result.dismiss) {
          api.post('/aprobar-accion', {id})
            .then(res => {
              getHistoryActions()
              swal.fire({
                title: "Acción aprobada!",
                icon: "success",
              });
            })
            .catch(err => {
              console.error(err)
              swal.fire({
                title: "Ocurrio un error lo sentimos",
                icon: "error",
              });
            })
        }
      })
    
    }

    const deleteAction = async (id) => {
      swal.fire({
        title: 'Quieres eliminar esta acción?',
        showCancelButton: true,
        confirmButtonText: `Si`,
        denyButtonText: 'No'
      }).then(async (result) => {
        if(!result.dismiss) {
          api.post('/desaprobar-accion', {id})
            .then(res => {
              getHistoryActions()
              swal.fire({
                title: "Acción eliminada!",
                icon: "success",
              });
            })
            .catch(error => {
              console.log("deleteAction -> error", error)
              swal.fire({
                title: "Ocurrio un error lo sentimos",
                icon: "error",
              });
            })
         
        }
      }).catch(err => {
        console.log("deleteAction -> SweetAlert -> err ", err)
      })
     
    }

    // component did mountx
    useEffect(() => {
        async function get() {
            await getHistoryActions()
        }
        get()
    }, [])

    // did update
    useEffect(() => {
      async function get() {
        setTipo(props.tipo)
        setIdsenal(props.idsenal)
        await getHistoryActions('props')
      }

      get()

    }, [props])

    const ToolbarBtn = () => {
      return (
        <Plugin name="ToolbarBtn">
          <Template name="toolbarContent">
            <div className="float-left mr-2">
              <label>Desde</label>
              <input
                type="date"
                name="desde"
                onChange={(e) => setDesde(e.target.value)}
                value={desde}
              />
            </div>
            <div className="float-left mr-2">
              <label>Hasta</label>
              <input
                type="date"
                name="hasta"
                onChange={(e) => setHasta(e.target.value)}
                disabled={desde ? false : true}
                value={hasta}
              />
            </div>

            {idsenal ? 
              <div
                className="chip float-left"
                onClick={(event) => setIdsenal('')}
              >
                <i className="material-icons tiny right" style={{lineHeight: '32px'}}>close</i> ID: {idsenal}  
              </div> : ''}

            <div className="float-left">
              <button
                className="btn-floating btn float-left waves-light waves-effect ml-2 mt-10"
                title="Buscar..."
                onClick={getHistoryActions}
              >
                <i className="material-icons">search</i>
              </button>
            </div>
            <TemplatePlaceholder />
          </Template>
        </Plugin>
      );
    }

    return (
      <Paper className="mt-1" style={{ width: "100%" }}>
        <Grid rows={rows} columns={columns}>
          <SortingState
            defaultSorting={[{ columnName: "idsenal", direction: "asc" }]}
          />
          <IntegratedSorting />
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions}/>
          <PagingState defaultCurrentPage={0} pageSize={15} />
          <IntegratedPaging />
          <VirtualTable />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowHeight={30} />
          <PagingPanel />
          <Toolbar/>
          <ToolbarBtn />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columnsExport}
          onSave={onSave}
          filters={filters}
        />
      </Paper>
    );
}

export default HistoryActions;
