import React, { Component } from "react";
import api from "../../helpers/api"
import moment from "moment";
import swal from "sweetalert2";
import auth from "../../helpers/auth";
import { compressImage } from "../../helpers/imagesCompress";

export default class FormSenal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cod_nuevo: "",
      cod_man: "",
      idsenal: "",
      nom_senal: "",
      classenal: "",
      principal: "",
      sec_1: "",
      sec_2: "",
      num: "",
      medidas: "",
      texto: "",
      ubi: "",
      ori: "",
      x_coord: -33.405855,
      y_coord: -70.543372,
      images: "",
      cuadrante: "",
      status: "pendiente",
      desde: moment().format("YYYY-MM-DD H:mm:ss"),
      user_id: null,
      instalacion: moment().format("YYYY-MM-DD H:mm:ss"),

      // autocomplete data
      ac_calles: {},
      ac_orientaciones: {},
      ac_ubicaciones: {},
      ac_tipos: {},
      ac_cod_nuevo: {},
      ac_cod_man: {},
      ac_clases: {},
      ac_textos: {},
      ac_medidas: {},

      // MAPA
      newMap: undefined,
      marker: undefined,
      
    };

    this.form = React.createRef();
  }

  async componentDidMount() {
    api.get("/signals/autocompletes").then((res) => {
      const {
        calles,
        orientaciones,
        ubicaciones,
        tipos,
        cod_nuevo,
        cod_man,
        clases,
        textos,
        medidas,
      } = res.data;

      this.setState({
        ac_calles: calles,
        ac_orientaciones: orientaciones,
        ac_ubicaciones: ubicaciones,
        ac_tipos: tipos,
        ac_cod_nuevo: cod_nuevo,
        ac_cod_man: cod_man,
        ac_clases: clases,
        ac_textos: textos,
        ac_medidas: medidas,
      });

      window.$("input#nom_senal").autocomplete({
        data: this.state.ac_tipos,
      });

      window.$("input#texto").autocomplete({
        data: this.state.ac_textos,
      });

      window.$("input#cod_nuevo").autocomplete({
        data: this.state.ac_cod_nuevo,
      });

      window.$("input#cod_man").autocomplete({
        data: this.state.ac_cod_man,
      });

      window.$("input#principal").autocomplete({
        data: this.state.ac_calles,
      });

      window.$("input#sec_1").autocomplete({
        data: this.state.ac_calles,
      });

      window.$("input#sec_2").autocomplete({
        data: this.state.ac_calles,
      });

      window.$("input#ubi").autocomplete({
        data: this.state.ac_ubicaciones,
      });

      window.$("input#ori").autocomplete({
        data: this.state.ac_orientaciones,
      });

      window.$("input#medidas").autocomplete({
        data: this.state.ac_medidas,
      });

      window.$("input#classenal").autocomplete({
        data: this.state.ac_clases,
      });
    });
    this.getUserData()
    await this.setMap();
  }

  getUserData = async () => {
    const data = await auth.getData();
    this.setState({ user_id : data.sub });
  };

  setMap = async () => {
    const { newMap } = this.state;
    if (newMap !== undefined) newMap.remove();
    await this.getCoords();
    var mapa = window.L.map("new-map", {
      center: [this.state.x_coord, this.state.y_coord],
      zoom: 17,
    });

    window.L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      foo: "bar",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors,',
      zIndex: 1,
      transparent: true,
    }).addTo(mapa);

    window.L.tileLayer
      .wms("/geoserver/tu/wms?", {
        layers: "tu:sectores",
        version: "1.1",
        zIndex: 2,
        transparent: true,
        format: "image/png",
      })
      .addTo(mapa);

    this.setState({ newMap: mapa });
    let marker = window.L.marker([this.state.x_coord, this.state.y_coord], {
      draggable: true,
      autoPan: true,
    }).addTo(mapa);

    this.setState({ marker });
    const t = this;

    marker.on("moveend", async function (e) {
      t.setState({
        x_coord: e.target._latlng.lng,
        y_coord: e.target._latlng.lat,
      });
    });
  };

  invalidateSize = async () => {
    this.state.newMap.invalidateSize();
  };

  getCoords() {
    const getLocation = new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
      
          this.setState({
            x_coord: lat,
            y_coord: lng,
          });
      
          resolve({lat, lng})
        },
        (error) => resolve(),
        { enableHighAccuracy: true }
      );
    })

    return getLocation;
  }

  coordsSuccess = async (position) => {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;

    this.setState({
      lat: lat,
      lng: lng,
    });

    this.setMap();
  };

  handleSubmitForm = async (event) => {
    try {
      event.preventDefault();

      const data = new FormData();

      data.append("cod_nuevo", document.getElementById("cod_nuevo").value);
      data.append("cod_man", document.getElementById("cod_man").value);
      data.append("idsenal", document.getElementById("idsenal").value);
      data.append("nom_senal", document.getElementById("nom_senal").value);
      data.append("classenal", document.getElementById("classenal").value);
      data.append("principal", document.getElementById("principal").value);
      data.append("sec_1", document.getElementById("sec_1").value);
      data.append("sec_2", document.getElementById("sec_2").value);
      data.append("num", document.getElementById("num").value);
      data.append("medidas", document.getElementById("medidas").value);
      data.append("texto", document.getElementById("texto").value);
      data.append("ubi", document.getElementById("ubi").value);
      data.append("ori", document.getElementById("ori").value);
      data.append("x_coord", this.state.x_coord);
      data.append("y_coord", this.state.y_coord);
      data.append("cuadrante", document.getElementById("cuadrante").value);
      data.append("status", this.state.status);
      data.append("desde", moment().format("YYYY-MM-DD H:mm:ss"));
      data.append("user_id", this.state.user_id);
      data.append("type", "INSTALACION");
      data.append("descripcion", "INSTALACION DE SEÑAL");
      data.append("instalacion", moment().format("YYYY-MM-DD H:mm:ss"));

      for (let i = 0; i < this.state.images.length; i++) {
        const imageCompressed = await compressImage(this.state.images[i])
        data.append("images", imageCompressed);
      }

      api.post("/signals", data)
        .then((res) => {
          swal.fire({
            title: "Señal agregada",
            icon: "success",
          });
          this.form.current.reset();
          window.$("#modal-new-signal").modal("close");
        })
        .catch((err) => {
          swal.fire({
            title: "Error inesperado",
            icon: "error",
          });
        });
    } catch (error) {
      console.log("handleSubmitForm -> error", error)
    }

    // this.form.current.submit();
  };

  render() {
    return (
      <div className="row">
        <div className="col s12">
          <div className="card">
            <form
              className="col s12 "
              style={{ marginTop: "20px" }}
              onSubmit={(event) => this.handleSubmitForm(event)}
              ref={this.form}
            >
              <div className="card-content">
                <h3 className="card-title">Nueva Señal</h3>
                <div className="row">
                  <div className="col s12 mt-2">
                    <div
                      id="new-map"
                      style={{ height: 300, width: "100%", flex: 1 }}
                    ></div>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="nom_senal"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ nom_senal: e.target.value })
                      }
                      required
                    />
                    <label htmlFor="nom_senal">Tipo</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="texto"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => this.setState({ texto: e.target.value })}
                    />
                    <label htmlFor="texto">Texto</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      id="idsenal"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ idsenal: e.target.value })
                      }
                      required
                    />
                    <label htmlFor="idsenal">ID</label>
                  </div>

                  <div className="input-field col s12 m6">
                    <input
                      id="cuadrante"
                      type="number"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ cuadrante: e.target.value })
                      }
                      required
                    />
                    <label htmlFor="cuadrante">Sector</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      id="cod_nuevo"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ cod_nuevo: e.target.value })
                      }
                      required
                    />
                    <label htmlFor="cod_nuevo">Cod. Nuevo</label>
                  </div>

                  <div className="input-field col s12 m6" style={{display: 'none'}}>
                    <input
                      id="cod_man"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ cod_man: e.target.value })
                      }
                      required
                      disabled={true}
                    />
                    <label htmlFor="cod_man">Cod. Man</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m8">
                    <input
                      id="principal"
                      type="text"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ principal: e.target.value })
                      }
                      required
                    />
                    <label htmlFor="principal">Calle Principal</label>
                  </div>

                  <div className="input-field col s12 m4">
                    <input
                      id="num"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => this.setState({ num: e.target.value })}
                      required
                    />
                    <label htmlFor="num">Número Calle</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="sec_1"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => this.setState({ sec_1: e.target.value })}
                    />
                    <label htmlFor="sec_1">Calle Secundaria 1</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <input
                      id="sec_2"
                      type="text"
                      onChange={(e) => this.setState({ sec_2: e.target.value })}
                    />
                    <label htmlFor="sec_2">Calle Secundaria 2</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      id="ubi"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => this.setState({ ubi: e.target.value })}
                    />
                    <label htmlFor="ubi">Ubicacion</label>
                  </div>

                  <div className="input-field col s12 m6">
                    <input
                      id="ori"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => this.setState({ ori: e.target.value })}
                    />
                    <label htmlFor="ori">Orientacion</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <input
                      type="text"
                      id="medidas"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ medidas: e.target.value })
                      }
                    />
                    <label htmlFor="medidas">Medidas</label>
                  </div>

                  <div className="input-field col s12 m6">
                    <input
                      type="text"
                      id="classenal"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({ classenal: e.target.value })
                      }
                    />
                    <label htmlFor="classenal">Clase</label>
                  </div>
                </div>

                <div className="row">
                  <div className="file-field input-field mb-4 col s12">
                    <div className="btn btn-sm">
                      <span>Fotos</span>
                      <input
                        id="images"
                        name="images"
                        type="file"
                        multiple
                        onChange={(e) => {
                          this.setState({ images: e.target.files })
			//Comentada el 24-11-2020 13:40∑
                          //this.handleImage(e.target.files)
                        }}
                        required
                      />
                    </div>

                    <div className="file-path-wrapper">
                      <input
                        className="file-path validate"
                        type="text"
                        placeholder="Puedes ingresar mas de una foto..."
                      />
                    </div>
                  </div>
                </div>

               
              </div>

              <div className="card-action mb-4">
                <button className="btn btn-small float-right" type="submit">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
