import React, { useState, useEffect } from 'react'
import { hasPermission } from '../helpers/auth'

const PermissionComponent = ({permission, component}) => {
    const [permmitted, setPermitted] = useState(false);

    useEffect(() => {
        async function userHasPermission() {
            const isPermitted = await hasPermission(permission);
            setPermitted(isPermitted);
        }
        userHasPermission()
    }, [permission])
    
    return (
        <>{permmitted ? component : ''} </>
    )
}

export default PermissionComponent
