import React, { useEffect, useState } from "react";
import InputFilter from "./InputFilter";

const Filter = React.forwardRef((props, ref) => {
  const [chips, setChips] = useState([])
  useEffect(() => {
    window.$(".modal").modal();
    window.$(".dropdown-trigger").dropdown();
  }, []);

  

  const update = (value, type) => {
    props.updFilters(value, type);
  }

  // useEffect(() => {
  //   filtersChips();
  // }, [props.queryFilter])

  const filtersChips = () => {
    let chips = [];
    for (const key in props.queryFilter) {
      if (props.queryFilter.hasOwnProperty(key)) {
        const element = props.queryFilter[key];
        element.map(item => {
          chips.push( <div
              className="chip"
              onClick={(event) => {
                event.preventDefault();
                update(item, key)
              }}
            >
              <i className="material-icons tiny right" style={{lineHeight: '32px'}}>close</i>{item}   
          </div>)
          return true;
        })
      }
    }
    setChips(chips);
  }

  useEffect(() => {
    filtersChips(props.queryFilter);
  }, [props.queryFilter]);

  const { filters, updFilters } = props;

  console.log(filters)

  return (
    <div ref={ref}>
      <a
        className="waves-effect waves-light btn modal-trigger"
        href="#modal1"
        style={{ float: "right", margin: "10px" }}
      >
        <i className="material-icons">filter_list</i>
      </a>
      {/* Modal Structure */}
      <div id="modal1" className="modal " style={{ width: "80%" }}>
        <div className="modal-content">
          <div>
            <h4>Filtros</h4>
            {chips}
            <div className="row">
              <div className="col s12 m6">
                <div className="input-field">
                  <InputFilter
                    data={filters.sectores ? filters.sectores : {}}
                    id={"cuadrantes"}
                    updFilters={updFilters}
                  />
                  <label htmlFor="cuadrantes">Sectores</label>
                </div>
              </div>

              <div className="col s12 m6">
                <div className="input-field">
                  <InputFilter
                    data={filters.cod_nuevo ? filters.cod_nuevo : {}}
                    id={"codsNuevo"}
                    updFilters={updFilters}
                  />
                  <label htmlFor="codsNuevo">Cod. Nuevo</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col s12 m6">
                <div className="input-field">
                  <InputFilter
                    data={filters.nombres ? filters.nombres : {}}
                    id={"nombres"}
                    updFilters={updFilters}
                  />
                  <label htmlFor="nombres">Tipos</label>
                </div>
              </div>
              
              <div className="col s12 m6">
                <div className="input-field">
                  <InputFilter
                    data={filters.textos ? filters.textos : {}}
                    id={"textos"}
                    updFilters={updFilters}
                  />
                  <label htmlFor="textos">Frases</label>
                </div>
              </div>
             
            </div>

            <div className="row">
              
            <div className="col s12 m6">
                <div className="input-field">
                  <InputFilter
                    data={filters.principales ? filters.principales : {}}
                    id={"calles"}
                    updFilters={updFilters}
                  />
                  <label htmlFor="calles">Calles Principales</label>
                </div>
              </div>
             
            </div>

            <div className="row">
              <div className="col s12 m6">
                <div className="input-field">
                  <InputFilter
                    data={filters.sec_1 ? filters.sec_1 : {}}
                    id={"sec_1"}
                    updFilters={updFilters}
                  />
                  <label htmlFor="sec_1">Calle Sec 1</label>
                </div>
              </div>
              
              <div className="col s12 m6">
                <div className="input-field">
                  <InputFilter
                    data={filters.sec_2 ? filters.sec_2 : {}}
                    id={"sec_2"}
                    updFilters={updFilters}
                  />
                  <label htmlFor="sec_2">Calle Sec 2</label>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <a
            href="#!"
            className="modal-action modal-close waves-effect waves-light btn "
          >
            Cerrar
          </a>
        </div>
      </div>
    </div>
  );
});

export default Filter;
