import React, { useState, useRef, useCallback, useEffect } from 'react'
import api from '../../helpers/api';
import Paper from "@material-ui/core/Paper";
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
  Plugin, Template, TemplatePlaceholder
} from '@devexpress/dx-react-core';
import {
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
  } from "@devexpress/dx-react-grid";
  import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    ExportPanel,
    TableFilterRow,
  } from "@devexpress/dx-react-grid-material-ui";
import {saveAs} from 'file-saver';
import * as moment from 'moment'

const HistoryMantenciones = (props) => {
    const [rows, setRows] = useState([]);
    const [desde, setDesde] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [sector, setSector] = useState('');
    const [idsenal, setIdsenal] = useState('');
    const [tipo, setTipo] = useState('mantenciones');
    const [hasta, setHasta] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [filters, setFilters] = useState([]);

    const exporterRef = useRef(null);
    
    const columnMantenciones = [
      { name: "idsenal", title: "ID" },
      { name: "cuadrante", title: "Sector" },
      { name: "cod", title: "Codigo" },
      { name: "nom_senal", title: "Señal" },
      { name: "texto", title: "Texto" },
      { name: "principal", title: "Calle P" },
      { name: "num", title: "N° Calle" },
      { name: "sec_1", title: "Secundaria 1" },
      { name: "sec_2", title: "Secundaria 2" },
      { name: "fecha", title: "Fecha" },
      { name: "descripcion", title: "Tipo" },
      { name: "user_name", title: "Responsable" },
    ]

    const columnNoMantenciones = [
      { name: "idsenal", title: "ID" },
      { name: "cuadrante", title: "Sector" },
      { name: "cod", title: "Codigo" },
      { name: "nom_senal", title: "Señal" },
      { name: "texto", title: "Texto" },
      { name: "principal", title: "Calle P" },
      { name: "num", title: "N° Calle" },
      { name: "sec_1", title: "Secundaria 1" },
      { name: "sec_2", title: "Secundaria 2" },
    ]
    
    const [columns, setColumns] = useState(columnMantenciones);

    const idsenalPredicate = (value, filter) => value == filter.value;

    const [integratedFilteringColumnExtensions] = useState([
      { columnName: 'idsenal', predicate: idsenalPredicate },
    ]);

    // const tableColumnExtensions = [
    //   { columnName: "nom_senal", wordWrapEnabled: true },
    //   { columnName: "texto", wordWrapEnabled: true },
    //   { columnName: "principal", wordWrapEnabled: true },
    //   { columnName: "texto", wordWrapEnabled: true },
    //   { columnName: "sec_1", wordWrapEnabled: true },
    //   { columnName: "sec_2", wordWrapEnabled: true },
    //   { columnName: "descripcion", wordWrapEnabled: true },
    // ];

    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
    }
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
      }, [exporterRef]);

    const getHistoryManteciones = async (from = 'state') => {
      let h_desde  = desde; 
      let h_hasta = hasta;
      let h_sector = sector;
      let h_tipo = tipo;
      let h_idsenal = idsenal;

      if(from === 'props') {
        h_desde  = props.desde;
        h_hasta = props.hasta;
        h_sector = props.sector;
        h_tipo = props.tipo;
        h_idsenal = props.idsenal;
      }
      
      setColumns(h_tipo !== 'mantenciones' ? columnNoMantenciones : columnMantenciones);
        
        let query = `desde=${h_desde}&hasta=${h_hasta}&tipo=${h_tipo}&idsenal=${idsenal}`;
        if(h_sector) query = `${query}&sector=${h_sector}`
        const result = await api.get(`/mantenciones/history?${query}`);

        setRows(result.data);
    }

    // component did mountx
    useEffect(() => {
        async function get() {
            await getHistoryManteciones()
        }

        get()
    }, [])

    // did update
    useEffect(() => {
      async function get() {
        setDesde(props.desde)
        setHasta(props.hasta)
        setSector(props.sector)
        setTipo(props.tipo)
        setIdsenal(props.idsenal)
        await getHistoryManteciones('props')
      }

      get()

    }, [props])

    const ToolbarBtn = () => {
      return (
        <Plugin name="ToolbarBtn">
          <Template name="toolbarContent">
            <div className="float-left mr-2">
              <label>Desde</label>
              <input
                type="date"
                name="desde"
                onChange={(e) => setDesde(e.target.value)}
                value={desde}
              />
            </div>
            <div className="float-left mr-2">
              <label>Hasta</label>
              <input
                type="date"
                name="hasta"
                onChange={(e) => setHasta(e.target.value)}
                disabled={desde ? false : true}
                value={hasta}
              />
            </div>

            <div className="float-left mr-2">
              <label>Tipo</label>
              <select
                value={tipo} onChange={(e) => setTipo(e.target.value)}
                className='browser-default'
              >
                <option value="mantenciones">Capturadas</option>
                <option value="no_mantenciones">Pendientes</option>
              </select>

            </div>

            {sector ? 
              <div
                className="chip float-left"
              >
                <i className="material-icons tiny right" style={{lineHeight: '32px'}} onClick={(event) => setSector('')}>close</i> Sector: {sector}  
              </div> : ''}
            
              {idsenal ? 
              <div
                className="chip float-left"
                onClick={(event) => setIdsenal('')}
              >
                <i className="material-icons tiny right" style={{lineHeight: '32px'}}>close</i> ID: {idsenal}  
              </div> : ''}

            <div className="float-left">
              <button
                className="btn-floating btn float-left waves-light waves-effect ml-2 mt-10"
                title="Buscar..."
                onClick={getHistoryManteciones}
              >
                <i className="material-icons">search</i>
              </button>
            </div>
            <TemplatePlaceholder />
          </Template>
        </Plugin>
      );
    }

    return (
      <Paper className="mt-1" style={{ width: "100%" }}>
        <Grid rows={rows} columns={columns}>
          <SortingState
            defaultSorting={[{ columnName: "idsenal", direction: "asc" }]}
          />
          <IntegratedSorting />
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
          <PagingState defaultCurrentPage={0} pageSize={15} />
          <IntegratedPaging />
          <VirtualTable />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowHeight={30} />
          <PagingPanel />
          <Toolbar/>
          <ToolbarBtn />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
          filters={filters}
        />
      </Paper>
    );
}

export default HistoryMantenciones;
