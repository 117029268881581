import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as moment from 'moment'
import swal from "sweetalert2";

import { cqlFilter } from './cqlFilters';
import NewSolicitud from '../solicitudes/NewSolicitud';
import Solicitudes from '../../pages/solicitudes/Solicitudes';
import TableActions from '../acciones/TableActions';
import FormMantencion from '../acciones/FormMantencion';
import FormCambio from '../acciones/FormCambio';
import FormMover from '../acciones/FormMover';
import api from "../../helpers/api";

import auth from '../../helpers/auth';
import PopUp from './PopUp'

class Map extends Component {
  constructor(props) {
    super(props);

    // jquery como propiedad del compenent
    this.$ = window.$;

    this.state = {
      map: undefined,
      signals_layer: undefined,
      senal: null,
      lat: -33.405855,
      lng: -70.543372,
      action_type: null,
      user: undefined,
      endDate: this.props.endDate,
      // AUTOCOMPLETES
      autocompletes: {
        ac_calles: {},
        ac_orientaciones: {},
        ac_ubicaciones: {},
        ac_tipos: {},
        ac_cod_nuevo: {},
        ac_cod_man: {},
        ac_clases: {},
        ac_textos: {},
        ac_medidas: {},
      },
    };

    this.form_cambio = React.createRef();
    this.form_mover = React.createRef();
    this.form_mantencion = React.createRef();
    this.table_actions = React.createRef();
  }

  async componentDidMount() {
    this.setMapa();

    this.$('.modal').modal();
    const data = await auth.getData();
    this.setState({
      user: data,
    });

    api.get('/signals/autocompletes').then((res) => {
      const {
        calles,
        orientaciones,
        ubicaciones,
        tipos,
        cod_nuevo,
        cod_man,
        clases,
        textos,
        medidas,
      } = res.data;

      this.setState({
        autocompletes: {
          ac_calles: calles,
          ac_orientaciones: orientaciones,
          ac_ubicaciones: ubicaciones,
          ac_tipos: tipos,
          ac_cod_nuevo: cod_nuevo,
          ac_cod_man: cod_man,
          ac_clases: clases,
          ac_textos: textos,
          ac_medidas: medidas,
        },
      });
    });
  }

	async componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters !== this.props.filters 
      || prevProps.endDate !== this.props.endDate
      || prevProps.idsenal !== this.props.idsenal) {
			// let ids = this.props.signals.map(sig => sig.ogc_fid);
			// ids = ids.join("','");
			// this.setMapa(ids);
			this.updFilter();
		}
		//console.log('update: ', this.state.map)
		setTimeout(()=>{
			if(this.state.map) {
				this.state.map.invalidateSize();
			}
		}, 400);
	}

  async updFilter() {
    let cql_filter = await cqlFilter(this.props.filters, this.props.idsenal);
    this.setMapa(cql_filter);
  }

  async getCoords() {
    const getLocation = new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
      
          this.setState({
            lat: lat,
            lng: lng,
          });
      
          resolve({lat, lng})
        },
        (error) => {
          this.setState({
            lat: -33.405855,
            lng: -70.543372,
          })
          resolve() 
        },
        { enableHighAccuracy: true }
      );
    })

    return getLocation;
  }

  coordsSuccess = async (position) => {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;

    this.setState({
      lat: lat,
      lng: lng,
    });
  };

  setMapa = async (filter = null) => {
    const { map } = this.state;

    await this.getCoords();

    if (map !== undefined) map.remove();
    var mapa = window.L.map('map', {
      center: [this.state.lat, this.state.lng],
      zoom: 17,
	  minZoom: 8,
	  maxZoom: 25,
	  //maxNativeZoom: 18
    });

	window.L.control.scale().addTo(mapa);

    // if (this.props.signals.length > 0) {
    //   console.log("doing");
    //   this.props.signals.forEach(signal => {
    //     window.L.popup()
    //       .setLatLng({
    //         lat: signal.y_coord,
    //         lng: signal.x_coord
    //       })
    //       .setContent(`<p>COD: ${signal.idsenal}, ${signal.nom_senal}</p>`)
    //       .openOn(mapa);
    //   });true
    // }
    window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      foo: 'bar',
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors,',
      zIndex: 1,
      transparent: true,
	  //minZoom: 8,
	  maxZoom: 25,
	  maxNativeZoom: 18
    }).addTo(mapa);

    mapa.locate({setView: true, maxZoom:21});
    window.L.tileLayer
      .wms('/geoserver/tu/wms?', {
        layers: 'tu:sectores',
        version: '1.1',
        zIndex: 2,
        transparent: true,
        format: 'image/png',
		maxZoom: 25
      })
      .addTo(mapa);

      /**
     * Cambios por Eilhert Andrade
     * Agregar los parámetros a la variable viewparams del geoserver
     * los parámetros en este caso son las fechas DESDE y HASTA
     * El valor de DESDE es opcional y tiene un valor por defecto en el geoserver
     * El valor de HASTA por defecto se envía como parámetro el día actual.
     * Para observar en el mapa el estado de las señales de un día específico, los parámetros DESDE y HASTA deben tener
     * el mismo valor. Para el estado en un rango de fecha se colocan respectivamente la fecha inicial y final en DESDE y HASTA
     */
    var viewparams = [
      'DESDE:2020-01-01',
      'HASTA:'+ moment(this.props.end).format('YYYY-MM-DD')
    ];
    //var baseURL="/geoserver/tu/wms";
    //var BaseURL_params=encodeURI(baseURL+"?viewparams="+viewparams.join(';'));
    //fin de los cambios
    let senales = window.L.tileLayer
      .wms('/geoserver/tu/wms?', {
        layers: 'tu:vw_senales',
        version: '1.1',
        zIndex: 3,
        transparent: true,
        format: 'image/png',
		minZoom: 12,
		maxZoom: 25,
		viewparams: encodeURI(viewparams.join(';'))
      })
      .addTo(mapa);

    if (filter) {
      senales.setParams({ cql_filter: filter });
    }
    const t = this;

    mapa.on('click', async function (e) {

      let res = await api.get(
        `/signals/range-front?lng=${e.latlng.lng}&lat=${e.latlng.lat}${t.props.urlFilters ? `&${t.props.urlFilters}`: ''}${t.props.idsenal ? `&idsenal=${t.props.idsenal}` : ''}`
      );

      let data = res.data[0];

      if (data) {
		/*** Modif. por Yenci Brito 27-05-2020 */
        //mapa.panTo(e.latlng);
		mapa.setView(e.latlng,20) //con esta instruccion realiza centrado y zoom al objeto clickeado al mismo tiempo
        //mapa.setZoomAround(e.latlng, 20);
        t.setState({ senal: data });
        /** SE CREA EL COMPONENTE REACT */
        const pop = React.createElement(
          PopUp,
          {
            data: data,
            manuntenciones: t.manuntenciones,
            newSolicitud: t.modalNuevaSolicitud,
            solcitudes: t.modalSolicitudes,
            openDropdown: t.openDropdown,
            historyActions: t.modalHistoryActions,
            newMantencion: t.modalNewMantencion,
            newCambio: t.modalNewCambio,
            newMover: t.modalNewMovimiento,
            user: t.state.user,
            remove: t.remove,
            historyMantenciones: t.modalMantenciones,
          },
          'Popup'
        );
        // LUEGO SE RENDERIZA DENTRO DE UN DIV PARA PODER USARLO ENE L POPUP
        const div = document.createElement('div');
        ReactDOM.render(pop, div);

        window.L.popup()
          .setLatLng({ lat: data.y_coord, lng: data.x_coord })
          .setContent(div)
          .openOn(mapa);
      }
    });

    this.setState({
      map: mapa,
    });
  };

  openDropdown = async (dropdown) => {
    window.$(`#${dropdown}`).dropdown();
    window.$(`#${dropdown}`).dropdown('open');
  };

  popupMap = async (id) => {
    let res = await api.get(`/signals/find/${id}`);

    let data = res.data;

    if (data) {
      let latlng = { lat: data.y_coord, lng: data.x_coord };
		/*** Modif. por Yenci Brito 27-05-2020 */
		//this.state.map.setView(latlng,20) //con esta instruccion realiza centrado y zoom al objeto clickeado al mismo tiempo
      this.state.map.panTo(latlng);
      this.state.map.setZoomAround(latlng, 20);
      this.setState({ senal: data });

      /** SE CREA EL COMPONENTE REACT */
      const pop = React.createElement(
        PopUp,
        {
          data: data,
          manuntenciones: this.manuntenciones,
          newSolicitud: this.modalNuevaSolicitud,
          solcitudes: this.modalSolicitudes,
          openDropdown: this.openDropdown,
          historyActions: this.modalHistoryActions,
          newMantencion: this.modalNewMantencion,
          newCambio: this.modalNewCambio,
          newMover: this.modalNewMovimiento,
          user: this.state.user,
          remove: this.remove,
          historyMantenciones: this.modalMantenciones,
        },
        'Popup'
      );
      // LUEGO SE RENDERIZA DENTRO DE UN DIV PARA PODER USARLO ENE L POPUP
      const div = document.createElement('div');
      ReactDOM.render(pop, div);

      window.L.popup().setLatLng(latlng).setContent(div).openOn(this.state.map);
    }
  };

  modalSolicitudes = (data) => {
    this.props.activateTab(data.idsenal, 'solicitudes')
  };

  modalMantenciones = (idsenal) => {
    this.props.activateTab(idsenal, 'mantenciones')
  };

  modalNuevaSolicitud = () => {
    this.$('#modalNewSolicitud').modal('open');
  };

  closeModalNuevaSolicitud = () => {
    this.$('#modalNewSolicitud').modal('close');
  };

  modalHistoryActions = (data) => {
    this.props.activateTab(data.idsenal, 'history_actions')
  };


  modalNewMantencion = (data) => {
    this.setState({
      senal: data,
    });

    this.$('#modalNewMantencion').modal('open');
  };

  closeModalNewMantencion = () => {
    this.$('#modalNewMantencion').modal('close');
  };

  modalNewCambio = (data) => {
    this.setState({
      senal: data,
    });

    this.$('#modalNewCambio').modal('open');
  };

  closeModalNewCambio = () => {
    this.$('#modalNewCambio').modal('close');
  };

  modalNewMovimiento = (data) => {
    this.setState({
      senal: data,
    });

    this.$('#modalNewMovimiento').modal('open');

    this.form_mover.current.invalidateSize();
  };

  closeModalNewMovimiento = () => {
    this.$('#modalNewMovimiento').modal('close');
  };

  updateActions = () => {
    this.table_actions.current.getActions();
  };

  remove = async (data) => {
    swal.fire({
      title: 'Quieres desinstalar esta señal?',
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: 'No'
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if(!result.dismiss) {
        api.post("/action/remove", {idsenal: data.idsenal, id: data.ogc_fid})
          .then(res => {
            swal.fire({
              title: "Desinstalación ejecutada!",
              icon: "success",
            });
          })
          .catch(err => {
            console.log("remove -> err", err)
            swal.fire({
              title: "Ocurrio un error lo sentimos",
              icon: "error",
            });
          })
      }
    });
  }

  render() {
    const { user, senal } = this.state;
    // let styles
    // ( user )? styles={height: '100%', width: '100%', position: 'relative'} : styles={height: '100%', width: '100%', position: 'absolute'} 
    return (
      <div style={{ height: '100%' }}>
  
        {/* MODAL DE NUEVA SOLICITUD */}
        <div id="modalNewSolicitud" className="modal">
            <button
              className="btn-flat float-right"
              onClick={this.closeModalNuevaSolicitud}
            >
              <i className="material-icons">close</i>
            </button>
            <NewSolicitud idsenal={senal ? senal.idsenal : null} />
        </div>

        {/* MODAL DE NUEVA MANTENCION */}

        <div id="modalNewMantencion" className="modal">
            <button
              className="btn-flat float-right"
              onClick={this.closeModalNewMantencion}
            >
              <i className="material-icons">close</i>
            </button>
            {user ? (
              user.permisos.includes('senales_mantencion') ? (
                <FormMantencion
                  senal={this.state.senal}
                  autocomplete={this.state.autocompletes}
                  ref={this.form_mantencion}
                  update={this.updateActions}
                />
              ) : (
                ''
              )
            ) : (
              ''
            )}
        </div>

        {/* MODAL DE NUEVO CAMBIO */}

        <div id="modalNewCambio" className="modal">
            <button
              className="btn-flat float-right"
              onClick={this.closeModalNewCambio}
            >
              <i className="material-icons">close</i>
            </button>
            {user ? (
              user.permisos.includes('senales_cambiar') ? (
                <FormCambio
                  senal={this.state.senal}
                  autocomplete={this.state.autocompletes}
                  ref={this.form_cambio}
                />
              ) : (
                ''
              )
            ) : (
              ''
            )}
        </div>

        {/* MODAL DE NUEVO MOVIMIENTO */}

        <div id="modalNewMovimiento" className="modal">
            <button
              className="btn-flat float-right"
              onClick={this.closeModalNewMovimiento}
            >
              <i className="material-icons">close</i>
            </button>
            {user ? (
              user.permisos.includes('senales_modificar') ? (
                <FormMover
                  senal={this.state.senal}
                  autocomplete={this.state.autocompletes}
                  ref={this.form_mover}
                />
              ) : (
                ''
              )
            ) : (
              ''
            )}
        </div>
        
        <div id="map">
          <div
            className="leaflet-control"
            style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
          >
            <button
              className="waves-effect waves-light  btn-floating"
              onClick={() => {
                this.props.reset();
                this.setMapa();
              }}
            >
              <i className="material-icons">autorenew</i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
