import React, { Component } from "react";
import api from "../../helpers/api"
import auth from "../../helpers/auth"

export default class NewSolicitud extends Component {
  /**
   *
   * @param {*} props
   * @param props.idsenal
   * @param props.cod_man
   * @param props.lat ?
   * @param props.lng ?
   *
   */
  constructor(props) {
    super(props);

    // Capturar latitud y lng de la peticion
    this.state = {
      nombre: "",
      email: "",
      descripcion: "",
      lat: -33.405855,
      lng: -70.543372,
      idsenal: "",
      user: null,
      types: []
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    window.M.textareaAutoResize(window.$("#descripcion"));

    // let parsedUrl = new URL(window.location.href);
    // let idsenal = parsedUrl.searchParams.get("idsenal");

    // this.setState({
    //   idsenal,
    // });
    let t = this;
    this.getUserData()
    this.getTypes()
    window.navigator.geolocation.getCurrentPosition(function (position) {

      t.setState({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });

  }
  
  componentDidUpdate(prevProps, prevState) {
    if(prevProps !== this.props) {
      this.getUserData()
    }
  }

  getUserData = async () => {
    const data = await auth.getData();
    this.setState({ 
      user: data ? data : null,
      nombre: data ? data.nombre : null,
      email: data ? data.email : null
    });
  };

  async handleSubmitForm(event) {
    event.preventDefault();

    const data = {
      nombre: this.state.nombre,
      email: this.state.email,
      descripcion: this.state.descripcion,
      lat: this.state.lat,
      lng: this.state.lng,
      idsenal: this.props.idsenal,
      user_id: this.state.user ? this.state.user.sub : null,
    };
    const res = await api.post("/solicitudes", data);

    if (res.data === "done") {
      this.form.current.reset();

      document.getElementById("alert").style.display = "block";
    } else {
      // TODO: mostrar notificacion de error
      console.error("err >> ", res);
    }
  }

  async getTypes () {
    const {data} = await api.get('/mantenciones/types');
    this.setState({
      types: data.tipos_solicitud,
      descripcion: data.tipos_solicitud[0]
    })
  }

  render() {
    return (
      <div className="container row">
        <div className="col s12">
          <div
            className="card-alert card green lighten-5"
            id="alert"
            style={{ display: "none" }}
          >
            <div className="card-content green-text">
              <p>Solicitud Agregada</p>
            </div>
            <button
              type="button"
              className="close green-text"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() =>
                (document.getElementById("alert").style.display = "none")
              }
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="card">
            <div className="card-content">
              <h4 className="card-title">Nueva solicitud </h4>
              <form
                className="col s12 "
                style={{ marginTop: "20px" }}
                onSubmit={(event) => this.handleSubmitForm(event)}
                ref={this.form}
              >
                {this.state.user ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="input-field col s12 m6">
                      <input
                        id="nombre"
                        type="text"
                        disabled={this.state.user ? true : false}
                        onChange={(e) =>
                          this.setState({ nombre: e.target.value })
                        }
                        value={this.state.nombre}
                      />
                      <label htmlFor="nombre">Nombre</label>
                    </div>
                    <div className="input-field col s12 m6">
                      <input
                        id="email"
                        type="email"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        value={this.state.email}
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="descripcion">Descripción</label>
                    <select id="descripcion" className="browser-default" value={this.state.descripcion} onChange={e => this.setState({descripcion: e.target.value})}>
                      {this.state.types.map((type, index) => <option value={type} key={index}>{type}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row center-align mb-4 mt-2">
                  <button className="waves-light waves-effect btn-small col s12 m4 offset-m4 ">
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
