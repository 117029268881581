import React, { useState, useRef, useCallback, useEffect } from 'react'
import api from '../../helpers/api';
import Paper from "@material-ui/core/Paper";
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
  Plugin, Template, TemplatePlaceholder
} from '@devexpress/dx-react-core';
import {
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
  } from "@devexpress/dx-react-grid";
  import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    ExportPanel,
    TableFilterRow,
  } from "@devexpress/dx-react-grid-material-ui";
import {saveAs} from 'file-saver';
import * as moment from 'moment'

const TableSolicitudes = (props) => {
    const [rows, setRows] = useState([]);
    const [desde, setDesde] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    // const [sector, setSector] = useState('');
    const [idsenal, setIdsenal] = useState('');
    const [hasta, setHasta] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [filters, setFilters] = useState([]);

    const exporterRef = useRef(null);
    
    const [columns, setColumns] = useState([
      { name: "senal_id", title: "ID Señal" },
      // { name: "cod_man", title: "Cod. Señal" },
      { name: "nombre", title: "Nombre solici." },
      { name: "email", title: "Email solici." },
      { name: "created", title: "Creada" },
      { name: "descripcion", title: "Descripcion" },
      { name: "completed", title: "Completada?" },
      { name: "completed_date", title: "Fecha completada" },
    ]);

    const columnExtensions = [
      { columnName: "nombre", wordWrapEnabled: false },
      { columnName: "email", wordWrapEnabled: true },
      { columnName: "descripcion", wordWrapEnabled: true },
    ];

    const idsenalPredicate = (value, filter) => value == filter.value;

    const [integratedFilteringColumnExtensions] = useState([
      { columnName: 'senal_id', predicate: idsenalPredicate },
    ]);

    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `solicitudes-${desde}-${hasta}.xlsx`);
        });
    }
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
      }, [exporterRef]);

    const getSolicitudes = async (from = 'state') => {
      try {
        let h_desde  = desde; 
        let h_hasta = hasta;
        let h_idsenal = idsenal;

        if(from === 'props') {
          h_idsenal = props.idsenal;
        }
        
        
        let query = `desde=${h_desde}&hasta=${h_hasta}&idsenal=${h_idsenal}`;
        const result = await api.get(`/solicitudes?${query}`);

        setRows(result.data);
      } catch (error) {
        console.error(error)
      }
    }

    // component did mountx
    useEffect(() => {
        async function get() {
            await getSolicitudes()
        }
        get()
    }, [])

    // did update
    useEffect(() => {
      async function get() {
        setIdsenal(props.idsenal)
        await getSolicitudes('props')
      }

      get()

    }, [props])

    const ToolbarBtn = () => {
      return (
        <Plugin name="ToolbarBtn">
          <Template name="toolbarContent">
            <div className="float-left mr-2">
              <label>Desde</label>
              <input
                type="date"
                name="desde"
                onChange={(e) => setDesde(e.target.value)}
                value={desde}
              />
            </div>
            <div className="float-left mr-2">
              <label>Hasta</label>
              <input
                type="date"
                name="hasta"
                onChange={(e) => setHasta(e.target.value)}
                disabled={desde ? false : true}
                value={hasta}
              />
            </div>

            {idsenal ? 
              <div
                className="chip float-left"
                onClick={(event) => setIdsenal('')}
              >
                <i className="material-icons tiny right" style={{lineHeight: '32px'}}>close</i> ID: {idsenal}  
              </div> : ''}

            <div className="float-left">
              <button
                className="btn-floating btn float-left waves-light waves-effect ml-2 mt-10"
                title="Buscar..."
                onClick={getSolicitudes}
              >
                <i className="material-icons">search</i>
              </button>
            </div>
            <TemplatePlaceholder />
          </Template>
        </Plugin>
      );
    }

    return (
      <Paper className="mt-1" style={{ width: "100%" }}>
        <Grid rows={rows} columns={columns}>
          <SortingState
            defaultSorting={[{ columnName: "senal_id", direction: "asc" }]}
          />
          <IntegratedSorting />
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions}/>
          <PagingState defaultCurrentPage={0} pageSize={15} />
          <IntegratedPaging />
          <VirtualTable />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowHeight={30} />
          <PagingPanel />
          <Toolbar/>
          <ToolbarBtn />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
          filters={filters}
        />
      </Paper>
    );
}

export default TableSolicitudes;
