import React, { useState, useRef, useCallback, useEffect } from 'react'
import api from '../../helpers/api';
import Paper from "@material-ui/core/Paper";
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
  Plugin, Template, TemplatePlaceholder
} from '@devexpress/dx-react-core';
import {
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
  } from "@devexpress/dx-react-grid";
  import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    ExportPanel,
    TableFilterRow,
  } from "@devexpress/dx-react-grid-material-ui";
import {saveAs} from 'file-saver';
import swal from "sweetalert2";
import * as moment from 'moment'
import NewUser from './NewUser';
import EditUser from './EditUser';

const Users = (props) => {
    const [rows, setRows] = useState([]);
    const [idsenal, setIdsenal] = useState('');
    const [filters, setFilters] = useState([]);
    const [editUser, seteditUser] = useState({});

    const exporterRef = useRef(null);
    // id, perfil_id, email, created, last_login, active, nombre, rol
    const [columns, setColumns] = useState([
      { name: "acciones", title: "Acciones"},
      { name: "email", title: "Email" },
      { name: "active", title: "Activo" },
      { name: "nombre", title: "Nombre" },
      { name: "rol", title: "Rol" },
      { name: "last_login", title: "Ultimo Ingreso" },
      { name: "created", title: "Creado" },
    ]);

    const columsExports = [
      { name: "email", title: "Email" },
      { name: "active", title: "Activo" },
      { name: "nombre", title: "Nombre" },
      { name: "rol", title: "Rol" },
      { name: "last_login", title: "Ultimo Ingreso" },
      { name: "created", title: "Creado" },
    ]

    const columsExt = [
      { columnName: "email", wordWrapEnabled: true },
      { columnName: "nombre", wordWrapEnabled: true },
    ]

    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `usuarios.xlsx`);
        });
    }
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
      }, [exporterRef]);

    const getUsers = async () => {
      try {
        const result = await api.get(`/users`);

        const data = result.data.map(item => {
          const {active} = item;
          item.created = moment(item.created).format('YYYY-MM-DD');
          item.active = item.active ? 'SI' : 'NO';
          item.rol = item.rol.toUpperCase()
          item.acciones = (
            <>
              <button
                className="waves-effect waves-light btn-small btn-floating"
                title="Modificar Usuario"
                onClick={() => modalEditUser(item)}
              >
                <i className="material-icons">create</i>
              </button>
              <button
                className="waves-effect waves-light btn-small btn-floating"
                title={active ? 'Bloquear' : 'Desbloquear'}
                onClick={() => lockAndUnlock(item.id, active)}
              >
                <i className="material-icons">{active ? 'lock' : 'lock_open'}</i>
              </button>
            </>
          )
          return item;
        })

        setRows(data);
      } catch (error) {
        console.error(error)
      }
    }

    // component did mountx
    useEffect(() => {
      async function get() {
        await getUsers()
      }
      get()

      window.$(".modal").modal();
    }, [])

    // did update
    useEffect(() => {
      getUsers()
    }, [props])

    const modalNewUser = () => {
      window.$("#modal-new-user").modal("open");
    };

    const closeModalNewUser = () => {
      window.$("#modal-new-user").modal("close")
    }

    const modalEditUser = (user) => {
      seteditUser(user);
      window.$("#modal-edit-user").modal("open");
    };

    const closeModalEditUser = () => {
      window.$("#modal-edit-user").modal("close")
    }

    const lockAndUnlock = async (id, active) => {
      const message = active 
        ? 'Quieres bloquear a este usuario?'
        : 'Quieres desbloquear a este usuario?';

      swal.fire({
        title: message,
        showCancelButton: true,
        confirmButtonText: `Si`,
        cancelButtonText: 'No'
      }).then(async (result) => {
        if(!result.dismiss) {
          api.post(`/users/enable/${id}`, {active: !active})
            .then(res => {
              getUsers()
              swal.fire({
                title: `Usuario ${active ? 'bloqueado' : 'desbloqueado'}!`,
                icon: "success",
              });
            })
            .catch(err => {
              console.error(err)
              swal.fire({
                title: "Ocurrio un error lo sentimos",
                icon: "error",
              });
            })
        }
      })
    }

    const ToolbarBtn = () => {
      return (
        <Plugin name="ToolbarBtn">
          <Template name="toolbarContent">
            <div className="float-left">
              <button
                className="btn-floating btn float-left waves-light waves-effect ml-2 mt-10"
                title="Nuevo Usuario"
                onClick={modalNewUser}
              >
                <i className="material-icons">add</i>
              </button>
            </div>
            <TemplatePlaceholder />
          </Template>
        </Plugin>
      );
    }

    return (
      <>
        <div id="modal-new-user" className="modal">
          <button
            className="btn-flat float-right"
            onClick={closeModalNewUser}
          >
            <i className="material-icons">close</i>
          </button>
          <NewUser closeModal={closeModalNewUser}/>
        </div>

        <div id="modal-edit-user" className="modal">
          <button
            className="btn-flat float-right"
            onClick={closeModalEditUser}
          >
            <i className="material-icons">close</i>
          </button>
          <EditUser closeModal={closeModalEditUser} user={editUser}/>
        </div>
        <Paper className="mt-1" style={{ width: "100%" }}>
          <Grid rows={rows} columns={columns}>
            <SortingState
              defaultSorting={[{ columnName: "email", direction: "asc" }]}
            />
            <IntegratedSorting />
            <FilteringState filters={filters} onFiltersChange={setFilters} />
            <IntegratedFiltering />
            <PagingState defaultCurrentPage={0} pageSize={15} />
            <IntegratedPaging />
            <VirtualTable columnExtensions={columsExt}/>
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowHeight={30} />
            <PagingPanel />
            <Toolbar/>
            <ToolbarBtn />
            <ExportPanel startExport={startExport} />
          </Grid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columsExports}
            onSave={onSave}
            filters={filters}
          />
        </Paper>
      </>
    );
}

export default Users;
