import Compressor from 'compressorjs';

export const compressImage = (image) => {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 1,
      maxWidth: 720,
      maxHeight: 960,
      success(result) {
        resolve(result)
      },
      error(err) {
        reject(err.message);
      },
    });
  })
} 