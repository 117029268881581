import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import * as moment from "moment"
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SelectionState,
  // IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  // Toolbar,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";

class TableSignals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "idsenal", title: "ID" },
        { name: "status", title: "Status" },
        { name: "cuadrante", title: "Sector" },
        { name: "cod_nuevo", title: "Cod. Nuevo" },
        { name: "nom_senal", title: "Tipo" },
        { name: "texto", title: "Texto" },
        { name: "principal", title: "Calle P" },
        { name: "num", title: "N° Calle" },
        { name: "sec_1", title: "Secundaria 1" },
        { name: "sec_2", title: "Secundaria 2" },
        { name: "instalacion", title: "Instalada" },
      ],
      rows: [],
      tableColumnExtensions: [
        { columnName: "nom_senal", wordWrapEnabled: true },
        { columnName: "texto", wordWrapEnabled: true },
        { columnName: "principal", wordWrapEnabled: true },
        { columnName: "texto", wordWrapEnabled: true },
        { columnName: "sec_1", wordWrapEnabled: true },
        { columnName: "sec_2", wordWrapEnabled: true },
        { columnName: "actions", wordWrapEnabled: true },
      ],
      selection: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.signals !== this.props.signals) {
      const signals = this.props.signals.map(item => {
        item.instalacion = item.instalacion ? moment(item.instalacion).format('YYYY/MM/DD') : null;
        return item;
      })
      
      this.setState({ rows: signals });
    }
  }

  setSelection = async (data) => {
    this.props.viewSignalOnMap(this.state.rows[data[0]].ogc_fid);
  };

  render() {
    const { columns, rows, selection } = this.state;

    return (
      <Paper style={{ width: "100%" }}>
        <Grid rows={rows} columns={columns}>
          <SortingState
            defaultSorting={[{ columnName: "idsenal", direction: "asc" }]}
          />
          <IntegratedSorting />
          <PagingState defaultCurrentPage={0} pageSize={15} />
          <IntegratedPaging />
          <SelectionState
            selection={selection}
            onSelectionChange={this.setSelection}
          />
          <VirtualTable columnExtensions={this.state.tableColumnExtensions} />
          <TableHeaderRow showSortingControls />
          <TableSelection selectByRowClick showSelectionColumn={false} />
          <PagingPanel />
        </Grid>
      </Paper>
    );
  }
}

export default TableSignals;
