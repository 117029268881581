export async function cqlFilter(filters, idsenal) {
  let cql_filter = [];
  // sectores
  let cuadrantes =
    filters.cuadrantes.length > 0
      ? "cuadrante IN ('" + filters.cuadrantes.join("','") + "')"
      : '';

  if (cuadrantes) cql_filter.push(cuadrantes);

  // tipos
  let nombres =
    filters.nombres.length > 0
      ? "nom_senal IN ('" + filters.nombres.join("','") + "')"
      : '';

  if (nombres) cql_filter.push(nombres);

  // textos
  let textos =
    filters.textos.length > 0
      ? "texto IN ('" + filters.textos.join("','") + "')"
      : '';
  if (textos) cql_filter.push(textos);

  // calles principales
  let principales =
    filters.calles.length > 0
      ? "principal IN ('" + filters.calles.join("','") + "')"
      : '';

  if (principales) cql_filter.push(principales);

  // calles secundarias 1
  let sec_1 =
    filters.sec_1.length > 0
      ? "sec_1 IN ('" + filters.sec_1.join("','") + "')"
      : '';

  if (sec_1) cql_filter.push(sec_1);

  // calle secundarias 2
  let sec_2 =
    filters.sec_2.length > 0
      ? "sec_2 IN ('" + filters.sec_2.join("','") + "')"
      : '';

  if (sec_2) cql_filter.push(sec_2);

  // cod nuevo
  let cods =
    filters.cod_nuevo.length > 0
      ? "cod_nuevo IN ('" + filters.cods.join("','") + "')"
      : '';

  if (cods) cql_filter.push(cods);

  // status
  cql_filter.push("status IN ('instalada','pendiente')");

  // idsenal
  if (idsenal) cql_filter.push(`idsenal = ${idsenal}`);

  // union de todos los filtros por "AND"
  cql_filter = cql_filter.join(' AND ');

  return cql_filter;
}
