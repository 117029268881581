import React, { useEffect } from "react";
import { cqlFilter } from "./cqlFilters";

const Export = ({ filters }) => {
  useEffect(() => {
    window.$(".modal").modal();
    window.$(".dropdown-trigger").dropdown();
  }, []);

  const excel = async () => {
    let cql_filter = await cqlFilter(filters);
    let url =
      "/geoserver/wfs?request=GetFeature&version=1.1.0&typeName=tu:senales&outputFormat=excel";
    if (cql_filter) url = url + "&cql_filter=" + cql_filter;

    window.open(url, "_blank");
  };

  const kmz = async () => {
    let cql_filter = await cqlFilter(filters);
    let url = "/geoserver/wms/kml?layers=tu:senales&format=kmz&mode=download";
    if (cql_filter) url = url + "&cql_filter=" + cql_filter;

    window.open(url, "_blank");
  };

  const shapeFile = async () => {
    let cql_filter = await cqlFilter(filters);
    let url =
      "/geoserver/wfs?request=GetFeature&version=1.1.0&typeName=tu:senales&outputFormat=shape-zip";
    if (cql_filter) url = url + "&cql_filter=" + cql_filter;

    window.open(url, "_blank");
  };

  return (
    <div>
      <a
        className="dropdown-trigger btn"
        href="#!"
        data-target="exports"
        style={{ float: "right", margin: "10px" }}
      >
        <i className="material-icons">file_download</i>
      </a>
      {/* Dropdown Structure */}
      <ul id="exports" className="dropdown-content">
        <li>
          <a href="#!" onClick={excel}>
            Excel
          </a>
        </li>
        <li>
          <a href="#!" onClick={kmz}>
            KMZ
          </a>
        </li>
        <li>
          <a href="#!" onClick={shapeFile}>
            Shapefile
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Export;
