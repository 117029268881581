import React, { useEffect, useRef, useState } from 'react'
import swal from "sweetalert2";
import api from '../../helpers/api'

const EditUser = (props) => {

  const [name, setname] = useState(props.user ? props.user.nombre : '')
  const [rol, setrol] = useState(props.user ? props.user.perfil_id : '')
  const [password, setpassword] = useState('')
  const formRef = useRef()

  const [roles, setroles] = useState([]);

  const getRoles = async () => {
    const response = await api.get('/roles');
    setroles(response.data);
  }

  useEffect(() => {
    getRoles()
  }, [])

  useEffect(() => {
    setname(props.user.nombre)
    setrol(props.user.perfil_id)
    setpassword('')
    console.log(props.user)
  }, [props.user])

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      // data de envio
      const data = { name, rol, password }
     
      await api.put(`/users/${props.user.id}`, data);
      swal.fire({
        title: "Usuario modificado!",
        icon: "success",
      });

      formRef.current.reset()
      setname('')
      setrol(roles[0].id)
      setpassword('')

      props.closeModal()
    } catch (error) {
      swal.fire({
        title: 'Ocurrio un error lo sentimos',
        icon: "error",
      });
    }
  }

  return (
    <div className="row">
    <div className="col s12">
      <div className="card">
        <form
          className="col s12"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <div className="card-content">
            <h3 className="card-title">Modificar Usuario</h3>
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="text"
                  autoComplete="off"
                  onChange={(e) => setname(e.target.value)}
                  value={name}
                  required
                />
                <label>Nombre</label>
              </div>

              <div className="input-field col s12">
                <input
                  type="password"
                  autoComplete="off"
                  aria-autocomplete="none"
                  onChange={(e) => setpassword(e.target.value)}
                  value={password}
                />
                <label>Contraseña</label>
              </div>

              <div className="input-field col s12">
                <select required id="rol" 
                  className="browser-default" 
                  onChange={(e) => setrol(e.target.value)}
                  value={rol}
                  >
                  {roles.map((item) => 
                    <option value={item.id} key={item.tipo} selected={item.id === rol}>{item.tipo.toUpperCase()}</option>
                  )})
                </select>
              </div>

              <div className="card-action mb-4">
                <button className="btn btn-small float-right" type="submit">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    
  </div>
  )
}

export default EditUser
