import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DatePicker, {registerLocale} from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
 
class Fecha extends React.Component {
  state = {
    endDate: new Date()
  };
 
  handleChange = (date) => {
    this.setState({
      endDate: date
    });
    this.props.onChangeDate(moment(date).format('YYYY-MM-DD'))
  };
 
  render() {
    return (
      <DatePicker 
      locale="es"
      dateFormat="dd/MM/yyyy" 
       selected={this.state.endDate}
       onChange={this.handleChange}
       withPortal
      />
    );
  }
}

export default Fecha