import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import auth from "../../helpers/auth";
import api from "../../helpers/api";
import swal from "sweetalert2";


import {
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

export default class TableActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actions", title: "Acciones" },
        { name: "user", title: "Responsable" },
        { name: "action_type", title: "Tipo" },
        { name: "fecha", title: "Fecha" },
        { name: "approved", title: "Aprobado" },
        { name: "descripcion", title: "Descripción" },
      ],
      rows: [],
      selection: [],
      status: "todas",
      defaultColumnWidths: [
        { columnName: "user", width: 180 },
        { columnName: "action_type", width: 100 },
        { columnName: "fecha", width: 180 },
        { columnName: "approved", width: 100 },
        { columnName: "actions", width: 180 },
        { columnName: "descripcion", width: 180 },
      ],
      user: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if(this.props.idsenal) this.getActions();
    }
  }

  handleShow = async (action) => {
   
  };

  handleSelection = async (data) => {
    this.handleShow(this.state.rows[data[0]]);
  };

  approveAction = async (id) => {
    api.post('/aprobar-accion', {id})
      .then(res => {
        console.log(res)
        this.getActions()
        swal.fire({
          title: "Acción aprobada!",
          icon: "success",
        });
      })
      .catch(err => {
        console.error(err)
        swal.fire({
          title: "Ocurrio un error lo sentimos",
          icon: "error",
        });
      })
  }

  getActions = async () => {
    /**
     * this.props.types = "MANUTENCION" || "TODAS";
     * this.status.status = "pendiente"  || "aprobado" || "todas";
     */
    const user = await auth.getData();
    api.get(
      `/actions?idsenal=${this.props.idsenal}${
        this.props.type ? "&type=" + this.props.type : ""
      }`
    )
      .then((res) => {
        let data = res.data;
        if (user) {
          if (user.permisos.includes("acciones_aprobar")) {
            data = res.data.map((row) => {
              if(row.approved === 'NO') {
                row.actions = (
                  <div>
                    <button
                      className="waves-effect waves-light btn-small btn-floating"
                      title="Aprobar"
                      onClick={() => this.approveAction(row.id)}
                    >
                      <i className="material-icons">done</i>
                    </button>
                  </div>
                );
              }

              return row;
            });
          }
        }

        this.setState({ rows: data });
      })
      .catch((err) => console.error(err));
    // console.log(res.data);
  };

  render() {
    const { rows, columns, defaultColumnWidths } = this.state;
    return (
      <div>
        <Paper className="col s12">
          <Grid rows={rows} columns={columns}>
            <PagingState defaultCurrentPage={0} pageSize={15} />
            <IntegratedPaging />
            <SearchState />
            <IntegratedFiltering />
            <SortingState
              defaultSorting={[{ columnName: "fecha", direction: "desc" }]}
            />
            <IntegratedSorting />
            {/* <VirtualTable height="auto" width="auto" columnExtensions={this.state.tableColumnExtensions} /> */}
            <Table />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel />
            <PagingPanel />
          </Grid>
        </Paper>
      </div>
    );
  }
}
