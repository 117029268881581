import React, { Component } from "react";
import api from "../../helpers/api"
import TableSolicitudes from "../../components/solicitudes/TableSolicitudes";

export default class Solicitudes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      solicitudes: [],
      idsenal: null,
      completed: false,
    };
  }

  async componentDidMount() {
    await this.getSolicitudes(this.props.idsenal);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.idsenal !== this.props.idsenal) {
      await this.getSolicitudes(this.props.idsenal);
    }
  }

  async getSolicitudes(idsenal, completed = "") {
    let solicitudes_url = `/solicitudes?idsenal=${idsenal}&completed=${completed}`;
    let solicitudes = await api.get(solicitudes_url);

    let data = solicitudes.data ? solicitudes.data : [];

    console.log("data >> ", data);

    this.setState({
      solicitudes: data,
    });
  }

  filtrarIdSenal = async () => {
    let id = document.getElementById("idsenal").value;

    await this.getSolicitudes(id);

    this.setState({
      idsenal: id,
    });
  };

  filtrarCompletadas = async () => {
    console.log("here");
    await this.getSolicitudes(this.state.idsenal, true);

    console.log(this.state.solicitudes);
  };

  resetTable = async () => {
    let parsedUrl = new URL(window.location.href);
    let idsenal = parsedUrl.searchParams.get("idsenal") || "";

    await this.getSolicitudes(idsenal);

    this.setState({
      idsenal: idsenal,
    });
  };

  render() {
    const { solicitudes } = this.state;

    return (
      <div>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col s12">
            <TableSolicitudes className="col s12" solicitudes={solicitudes} />
          </div>
        </div>
      </div>
    );
  }
}
