import React, { Component } from "react";
import Axios from "axios";
import auth from "../helpers/auth";
// sweet alert
import swal from "sweetalert2";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email: this.state.email,
      password: this.state.password,
    };

    Axios.post("/api/login", data)
      .then(async (res) => {
        const { token } = res.data;

        await auth.setToken(token);
        swal.fire({
          title: "Login Correcto!",
          icon: "success",
        });

        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        document.getElementById('alert').style.display = 'block'
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col s12">
          <div className="container">
            <div id="login-page" className="row">
              <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                <div class="card-alert card red" style={{display: 'none'}} id="alert">
                  <div class="card-content white-text">
                    <p>Usuario o contraseña incorrecta</p>
                  </div>
                  <button type="button" class="close red-text" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <form
                  className="login-form"
                  onSubmit={(e) => this.handleSubmit(e)}
                >
                  <div className="row">
                    <div className="input-field col s12">
                      <h5 className="ml-4">Login</h5>
                    </div>
                  </div>
                  <div className="row margin">
                    <div className="input-field col s12">
                      <i className="material-icons prefix pt-2">
                        person_outline
                      </i>
                      <input
                        id="username"
                        type="text"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        autoComplete="off"
                      />
                      <label htmlFor="username" className="center-align">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="row margin">
                    <div className="input-field col s12">
                      <i className="material-icons prefix pt-2">lock_outline</i>
                      <input
                        id="password"
                        type="password"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                      <label htmlFor="password">Password</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <button
                        className="btn waves-effect waves-light border-round col s12"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
