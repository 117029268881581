import React, { Component } from "react";
// import axios from "axios";

// components
import Map from "../components/map/Map";
import TableSignals from "../components/signals/TableSignals";
import Filter from "../components/signals/Filters";
import Export from "../components/map/Export";
import FormSenal from "../components/signals/FormSenal";
import auth from "../helpers/auth";
import Header from "../components/layout/Header";
import api from "../helpers/api";
import Fecha from "../components/map/Fecha";
import moment from 'moment';
import {
  VerticalLayout,
  Panel,
  Separator,
  View
} from "../lib/nice-react-layout";
import PermissionComponent from "../components/PermissionComponent";
import HistoryMantenciones from "../components/mantenciones/HistoryMantenciones";
import ResumeMantenciones from "../components/mantenciones/ResumeMantenciones";
import HistoryActions from "../components/acciones/HistoryActions";
import Solicitudes from "./solicitudes/Solicitudes";
import TableSolicitudes from "../components/solicitudes/TableSolicitudes";
import Users from "../components/users/Users";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signals: [],
      filters: [],
      urlFilters: '',
      queryFilter: {
        cuadrantes: [],
        nombres: [],
        textos: [],
        calles: [],
        cods: [],
        cod_nuevo: [],
        sec_1: [],
        sec_2: [],
      },
      user: null,
      idsenal: '',
      endDate: moment().format('YYYY-MM-DD'),
      tab: 'senales',
      m_sector: '',
      m_desde: moment().startOf('month').format('YYYY-MM-DD'),
      m_hasta: moment().endOf('month').format('YYYY-MM-DD'),
      m_tipo: 'mantenciones',
      action_idsenal: '',
      action_tipo: '',
    };

    this.map = React.createRef();
    this.formSenal = React.createRef();
    this.filtro = React.createRef();
  }
  componentDidMount() {
    window.$(".modal").modal();
    window.$(".dropdown-trigger").dropdown();

    this.getSignals();

    this.getFilters();

    this.getUserData();
  }

  getUserData = async () => {
    const data = await auth.getData();
    this.setState({ user: data });
  };

  getSignals = async (url = "") => {
    let can = await auth.hasPermission("senales");
    if (can) {
      let res = await api
        .get(`/signals?fecha=${this.state.endDate}&${url}&${this.state.idsenal ? `idsenal=${this.state.idsenal}` : ''}`);
      this.setState({ signals: res.data });
    }
  };

  getFilters = async (url = "") => {
    let can = await auth.hasPermission("senales");
    if (can) {
      const {data} = await api.get(`/signals/filter?${url}`);
      let newFilters = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const element = data[key];
          newFilters[key] = element.reduce((acc, val) => {
            const keys = Object.keys(val)
              acc = {
                ...acc,
                [val[keys[0]]] : '', 
              };
              return acc;
            }, {})
        }
      }
      this.setState({ filters: newFilters });
    }
  };

  updFilters = async (value, type) => {
    let queryFilters = this.state.queryFilter;
    let exists = false;
    // buscamos si ya existe el elemento en los filtros actuales
    queryFilters[type].forEach((element) => {
      if (element === value) exists = true;
    });
    // si existe lo removemos y sino existe lo agregamos
    if (exists) queryFilters[type] = queryFilters[type].filter((e) => e !== value);
    else queryFilters[type].push(value);

    // actualizamos los nuevos query filters 
    this.setState({
      queryFilter: { ...queryFilters },
    });
    // se arman los filters para el geoserver y la tabla de señales
    this.filtData(queryFilters);
  };

  filtData = async (filters) => {
    // let filters = this.state.queryFilter;
    let cuadrantes =
      filters.cuadrantes.length > 0
        ? "cuadrante=" + filters.cuadrantes.join("&cuadrante=")
        : "";

    cuadrantes = cuadrantes !== "" ? cuadrantes + "&" : cuadrantes;

    let nombres =
      filters.nombres.length > 0
        ? "nom_senal=" + filters.nombres.join("&cuadrante=")
        : "";

    nombres = nombres !== "" ? nombres + "&" : nombres;

    let textos =
      filters.textos.length > 0
        ? "texto=" + filters.textos.join("&texto=")
        : "";

    textos = textos !== "" ? textos + "&" : textos;

    let principales =
      filters.calles.length > 0
        ? "principal=" + filters.calles.join("&principal=")
        : "";

    principales = principales !== "" ? principales + "&" : principales;

    let sec_1 =
      filters.sec_1.length > 0
        ? "sec_1=" + filters.sec_1.join("&sec_1=")
        : "";

    sec_1 = sec_1 !== "" ? sec_1 + "&" : sec_1;

    let sec_2 =
      filters.sec_2.length > 0
        ? "principal=" + filters.sec_2.join("&principal=")
        : "";

    sec_2 = sec_2 !== "" ? sec_2 + "&" : sec_2;

    let cods =
      filters.cods.length > 0
        ? "cod_man=" + filters.cods.join("&cod_man=")
        : "";

    cods = cods !== "" ? cods + "&" : cods;

    let cod_nuevo =
      filters.cod_nuevo.length > 0
        ? "cod_nuevo=" + filters.cod_nuevo.join("&cod_nuevo=")
        : "";

    cod_nuevo = cod_nuevo !== "" ? cod_nuevo + "&" : cod_nuevo;

    let url = `${cuadrantes}${nombres}${textos}${principales}${cods}${cod_nuevo}${sec_1}${sec_2}`;

    this.setState({
      urlFilters: url
    })

    this.getSignals(url);
    this.getFilters(url);
  };

  reset = async () => {
    // window.location.reload();

    await this.setState({
      queryFilter: {
        cuadrantes: [],
        nombres: [],
        textos: [],
        calles: [],
        cods: [],
        cod_nuevo: [],
        sec_1: [],
        sec_2: [],
      },
    });
    this.getSignals();
    this.getFilters();
  };

  viewSignalOnMap = async (id) => {
    this.map.current.popupMap(id);

    window.scrollTo(0, 0);
  };

  modalNewSignal = async () => {
    await window.$("#modal-new-signal").modal("open");

    this.formSenal.current.invalidateSize();
  };

  _handleChangeDate = async (date) => {
    console.log(date)
    await this.setState(() => {
      return {endDate: date}
    });

    this.filtData(this.state.queryFilter)
  };

  userIsLogin = (component) => {
    return this.state.user ? component : '';
  }

  ButtonNewSignal = () => {
    return (
      <button
        className="btn-floating btn float-left waves-light waves-effect ml-2"
        style={{ marginTop: "10px" }}
        onClick={() => this.modalNewSignal()}
        title="Nueva Señal"
      >
        <i className="material-icons">add</i>
      </button>
    );
  }
  // activa un tab y e envia el id señal
  activateTab = (idsenal = "", tab) => {
    this.setState({tab, action_idsenal: idsenal})
  }

  goToHistory = (type, sector, desde, hasta)  => {
    this.setState({ 
      m_sector: sector,
      m_desde: desde,
      m_hasta: hasta,
      m_tipo: type,
      tab: "mantenciones",
     })
  }

  // busca señales por el idsenal
  searchByIdsenal = () => {
    this.setState({
      idsenal: document.getElementById('search_idsenal').value
    })

    sessionStorage.setItem('idsenal', document.getElementById('search_idsenal').value)

    this.filtData(this.state.queryFilter)
  }

  // tabDiv = (tab, component ) => {
  //   return
  //     (<div >
  //       {component}
  //     </div>)
  // }

  render() {
    return (
      <div>
        <View>
          <VerticalLayout mockup>
            <Panel fixed fixedHeight={64}>
              <Header />
            </Panel>
            <Panel>
              <VerticalLayout mockup>
                <Panel
                  proportion={5}
                  render={({ size }) => (
                    <div style={{ height: "100%" }}>
                      <div id="modal-new-signal" className="modal">
                        <div className="modal-content">
                          <button
                            className="btn-flat float-right"
                            onClick={() =>
                              window.$("#modal-new-signal").modal("close")
                            }
                          >
                            <i className="material-icons">close</i>
                          </button>
                          <PermissionComponent
                            permission="senales_store"
                            component={<FormSenal ref={this.formSenal} />}
                          />
                        </div>
                      </div>
                      <Map
                        ref={this.map}
                        filters={this.state.queryFilter}
                        urlFilters={this.state.urlFilters}
                        reset={this.reset}
                        endDate={this.state.endDate}
                        idsenal={this.state.idsenal}
                        activateTab={this.activateTab}
                      />
                    </div>
                  )}
                ></Panel>
                {this.userIsLogin(
                  <Separator
                    customCss={{
                      height: "6px",
                      backgroundImage:
                        "url('./app-assets/images/icon/points.png')",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                )}
                {this.userIsLogin(
                  <Panel
                    proportion={2}
                    minHeight={20}
                    collapsible
                    collapseButtonContent="-"
                    collapseButtonCollapsedContent="+"
                    collapseButtonClass="btn-floating btn-small waves-effect waves-light btn-action-panel"
                  >
                    {/* TABS */}
                    <div
                      className="row"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <button
                        className={`btn btn-small waves-light waves-effect ml-1 ${this.state.tab === 'senales' ? 'orange accent-4' : ''}`}
                        onClick={() => this.setState({ tab: "senales" })}
                        title="Señales"
                      >
                        <i className="material-icons">warning</i>
                      </button>

                      <PermissionComponent
                        permission="reportes_mantencion"
                        component={
                          <button
                            className={`btn btn-small waves-light waves-effect ml-1 ${this.state.tab === 'r_mantenciones' ? 'orange accent-4' : ''}`}
                            onClick={() =>
                              this.setState({ tab: "r_mantenciones" })
                            }
                            title="Resumen mantenciones"
                          >
                            <i className="material-icons">build</i>
                          </button>
                        }
                      />

                      <PermissionComponent
                        permission="reportes_mantencion"
                        component={
                          <button
                            className={`btn btn-small waves-light waves-effect ml-1 ${this.state.tab === 'mantenciones' ? 'orange accent-4' : ''}`}
                            onClick={() =>
                              this.setState({ tab: "mantenciones" })
                            }
                            title="Historial mantenciones"
                          >
                            <i className="material-icons">reorder</i>
                            <i className="material-icons">build</i>
                          </button>
                        }
                      />

                      <PermissionComponent
                        permission="acciones_historial"
                        component={
                          <button
                          className={`btn btn-small waves-light waves-effect ml-1 ${this.state.tab === 'history_actions' ? 'orange accent-4' : ''}`}
                            onClick={() =>
                              this.setState({ tab: "history_actions" })
                            }
                            title="Historial acciones"
                          >
                            <i className="material-icons">reorder</i>
                          </button>
                        }
                      />

                      <PermissionComponent
                        permission="solicitudes_index"
                        component={
                          <button
                            className={`btn btn-small waves-light waves-effect ml-1 ${this.state.tab === 'solicitudes' ? 'orange accent-4' : ''}`}
                            onClick={() => this.activateTab("", "solicitudes")}
                            title="Historial solicitudes"
                          >
                            <i className="material-icons">announcement</i>
                          </button>
                        }
                      />

                      <PermissionComponent
                        permission="users"
                        component={
                          <button
                            className={`btn btn-small waves-light waves-effect ml-1 ${this.state.tab === 'users' ? 'orange accent-4' : ''}`}
                            onClick={() => this.activateTab("", "users")}
                            title="Usuarios"
                          >
                            <i className="material-icons">group</i>
                          </button>
                        }
                      />
                    </div>
                    {/** TAB SENALES */}
                    <div
                      style={{
                        display: `${
                          this.state.tab === "senales" ? "" : "none"
                        }`,
                      }}
                    >
                      <div className="row">
                        <div className="float-left ml-2">
                          <input
                            id="search_idsenal"
                            type="text"
                            autoComplete="off"
                            className="float-left"
                            placeholder="Buscar por id señal"
                          />
                        </div>

                        <button
                          className="btn-floating float-left btn waves-light waves-effect ml-1"
                          onClick={() => this.searchByIdsenal()}
                          style={{ marginTop: "10px" }}
                          title="Buscar por id"
                        >
                          <i className="material-icons">search</i>
                        </button>

                        <PermissionComponent
                          permission="senales_store"
                          component={this.ButtonNewSignal()}
                        />

                        <PermissionComponent
                          permission="senales"
                          component={
                            <Export filters={this.state.queryFilter} />
                          }
                        />
                        <PermissionComponent
                          permission="senales"
                          component={
                            <Filter
                              ref={this.filtro}
                              filters={this.state.filters}
                              updFilters={this.updFilters}
                              queryFilter={this.state.queryFilter}
                            />
                          }
                        />

                        {/**
                         * Elemento agregado por Eilhert Andrade
                         * Muestra el campo Fecha para buscar en el mapa las señales
                         */}
                        <div className="float-right">
                          <Fecha onChangeDate={this._handleChangeDate} />{" "}
                        </div>
                      </div>
                      <PermissionComponent
                        permission="senales"
                        component={
                          <TableSignals
                            signals={this.state.signals}
                            viewSignalOnMap={this.viewSignalOnMap}
                          />
                        }
                      />
                    </div>

                    {/** TAB MANTENCIONES */}
                    {this.state.tab === "mantenciones" ? (
                      <PermissionComponent
                        permission="reportes_mantencion"
                        component={
                          <HistoryMantenciones
                            sector={this.state.m_sector}
                            desde={this.state.m_desde}
                            hasta={this.state.m_hasta}
                            tipo={this.state.m_tipo}
                            idsenal={this.state.action_idsenal}
                          />
                        }
                      />
                    ) : (
                      ""
                    )}

                    {/* tab resume mantenciones */}
                    {this.state.tab === "r_mantenciones" ? (
                      <PermissionComponent
                        permission="reportes_mantencion"
                        component={
                          <ResumeMantenciones goToHistory={this.goToHistory} />
                        }
                      />
                    ) : (
                      ""
                    )}

                    {/** TAB ACCIONES */}
                    {this.state.tab === "history_actions" ? (
                      <PermissionComponent
                        permission="acciones_historial"
                        component={
                          <HistoryActions
                            idsenal={this.state.action_idsenal}
                            tipo={this.state.action_tipo}
                          />
                        }
                      />
                    ) : (
                      ""
                    )}

                    {/** TAB SOLICITUDES */}
                    {this.state.tab === "solicitudes" ? (
                      <PermissionComponent
                        permission="solicitudes_index"
                        component={
                          <TableSolicitudes
                            idsenal={this.state.action_idsenal}
                          />
                        }
                      />
                    ) : (
                      ""
                    )}
                    {/** TAB SOLICITUDES */}
                    {this.state.tab === "users" ? (
                      <PermissionComponent
                        permission="users"
                        component={<Users />}
                      />
                    ) : (
                      ""
                    )}
                  </Panel>
                )}
              </VerticalLayout>
            </Panel>
          </VerticalLayout>
        </View>
      </div>
    );
  }
}
