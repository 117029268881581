import React, { useState, useCallback, useRef } from "react";
import * as moment from "moment";
import api from "../../helpers/api";
import Paper from "@material-ui/core/Paper";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import {
  Plugin, Template, TemplatePlaceholder
} from '@devexpress/dx-react-core';
import { SummaryState, IntegratedSummary, CustomSummary } from "@devexpress/dx-react-grid";

import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  ExportPanel,
  TableSummaryRow,
  TableBandHeader,
} from "@devexpress/dx-react-grid-material-ui";
import { saveAs } from "file-saver";

const ResumeMantenciones = (props) => {
  const [year, setYear] = useState(parseInt(moment().format("YYYY")));
  const [month, setMonth] = useState(parseInt(moment().format("M")));
  const [rows, setRows] = useState([]);
  const [exportRows, setExportRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSummaryItems] = useState([
    { columnName: "total", type: "sum" },
    { columnName: "senales_capturadas", type: "sum" },
    { columnName: "senales_pendientes", type: "sum" },
  ]);
  const MONTHS = {
    1: "ENERO",
    2: "FEBRERO",
    3: "MARZO",
    4: "ABRIL",
    5: "MAYO",
    6: "JUNIO",
    7: "JULIO",
    8: "AGOSTO",
    9: "SEPTIEMBRE",
    10: "OCTUBRE",
    11: "NOVIEMBRE",
    12: "DICIEMBRE",
  };

  const columns = [
    { name: "cuadrante", title: "Sector" },
    { name: "total", title: "Total señales" },
    { name: "senales_capturadas", title: "Señales Capturadas" },
    { name: "senales_pendientes", title: "Señales Pendientes" },
  ];

  const [columnBands] = useState([
    {
      title: `Resumen de Mantenciones Mes: ${MONTHS[month]}, ${year}`,
      children: [
        { columnName: "cuadrante" },
        { columnName: "total" },
        { columnName: "senales_capturadas" },
        { columnName: "senales_pendientes" },
      ],
    },
  ]);

  const exporterRef = useRef(null);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "DataGrid.xlsx"
      );
    });
  };
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const getResumeManteniones = async () => {
    setLoading(true);
    const response = await api.get(
      `/mantenciones/resume?mes=${month}&year=${year}`
    );
    let e_rows = [];
    
    response.data.map((item) => {
      item.t_capturadas = item.senales_capturadas;
      item.t_pendientes = item.senales_pendientes;
      item.senales_capturadas = (
        <a
          href="#!"
          onClick={() => goToHistory(item.cuadrante, "mantenciones")}
        >
          {item.senales_capturadas}
        </a>
      );
      item.senales_pendientes = (
        <a
          href="#!"
          onClick={() => goToHistory(item.cuadrante, "no_mantenciones")}
        >
          {item.senales_pendientes}
        </a>
      );
      e_rows.push({
        cuadrante: item.cuadrante,
        total: item.total,
        senales_capturadas: item.t_capturadas,
        senales_pendientes: item.t_pendientes,
      });

      return item

    });
    setRows(response.data);
    setExportRows(e_rows)
    setLoading(false);
  };

  const goToHistory = (sector, type) => {
    const mes = month < 10 ? `0${month}` : month
    const desde = moment(`${year}-${mes}-01`)
      .startOf("month")
      .format("YYYY-MM-DD");
    const hasta = moment(`${year}-${mes}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");
    props.goToHistory(type, sector, desde, hasta);
  };

  const customizeHeader = (worksheet) => {
    const generalStyles = {
      font: { bold: true },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" },
        bgColor: { argb: "D3D3D3" },
      },
      alignment: { horizontal: "center" },
    };
    worksheet.mergeCells(1, 1, 1, 4);
    Object.assign(worksheet.getRow(1).getCell(1), generalStyles);
    worksheet.getRow(1).height = 20;
    worksheet.getRow(1).getCell(1).font = { bold: true, size: 16 };
    worksheet.getColumn(1).values = [
      `Resumen de Mantenciones Mes: ${MONTHS[month]}, ${year}`,
    ];
  };

  const getTotalSummaryValues = () => {
    return totalSummaryItems.map((summary) => {
      const { columnName, type } = summary;
      return IntegratedSummary.defaultCalculator( type, rows, (row) => {
        let data = row[columnName];
        if(columnName === 'senales_capturadas') {
          data = row.t_capturadas;
        } else if (columnName === 'senales_pendientes') {
          data = row.t_pendientes;
        }
        return data;
      });
    });
  };

  const ToolbarBtn = () => {
    return (
      <Plugin name="ToolbarBtn">
        <Template name="toolbarContent">
          <div className="float-left mr-1">
            <select
              className="browser-default"
              onChange={(e) => setMonth(e.target.value)}
              defaultValue={month}
            >
              <option value="1">ENERO</option>
              <option value="2">FEBRERO</option>
              <option value="3">MARZO</option>
              <option value="4">ABRIL</option>
              <option value="5">MAYO</option>
              <option value="6">JUNIO</option>
              <option value="7">JULIO</option>
              <option value="8">AGOSTO</option>
              <option value="9">SEPTIEMBRE</option>
              <option value="10">OCTUBRE</option>
              <option value="11">NOVIEMBRE</option>
              <option value="12">DICIEMBRE</option>
            </select>
          </div>

          <div className="s1 float-left mr-1">
            <input
              type="number"
              max={parseInt(moment().format("YYYY"))}
              onChange={(e) => setYear(e.target.value)}
              defaultValue={year}
            ></input>
          </div>

          <div className="float-left">
            <button
              className="btn btn-block waves-light waves-effect"
              onClick={getResumeManteniones}
            >
              Buscar
            </button>
          </div>

          <TemplatePlaceholder />
        </Template>
      </Plugin>
    );
  }

  return (
    <>
      <div className="row mt-2 mb-1">
        <div className="col s12">
          <Paper style={{ width: "100%" }}>
            {/** TODO: escribi formulario de mantenciones */}
            <div
              className="progress"
              style={{ display: `${!loading ? "none" : ""}` }}
            >
              <div className="indeterminate"></div>
            </div>
            <Grid rows={rows} columns={columns}>
              <SummaryState totalItems={totalSummaryItems} />
              <CustomSummary totalValues={getTotalSummaryValues()} />
              <Table />
              <TableHeaderRow />
              <TableBandHeader columnBands={columnBands} />
              <TableSummaryRow />
              <Toolbar />
              <ToolbarBtn />
              <ExportPanel startExport={startExport} />
            </Grid>
            <GridExporter
              ref={exporterRef}
              rows={exportRows}
              columns={columns}
              onSave={onSave}
              totalSummaryItems={totalSummaryItems}
              customizeHeader={customizeHeader}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default ResumeMantenciones;
