import React, { useEffect, useRef, useState } from 'react'
import swal from "sweetalert2";
import api from '../../helpers/api'

const NewUser = (props) => {
  const [name, setname] = useState()
  const [rol, setrol] = useState()
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const formRef = useRef()

  const [roles, setroles] = useState([]);

  const getRoles = async () => {
    const response = await api.get('/roles');
    setroles(response.data);
    setrol(response.data[0].id)
  }

  useEffect(() => {
    getRoles()
    setpassword('')
    setemail('')
  }, [])

  useEffect(() => {
    setpassword('')
    setemail('')
  }, [props])

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      // data de envio
      const data = { name, rol, email, password }
     
      await api.post('/signup', data);
      swal.fire({
        title: "Usuario guardado!",
        icon: "success",
      });

      formRef.current.reset()
      setname('')
      setrol(roles[0].id)
      setemail('')
      setpassword('')

      props.closeModal()
    } catch (error) {
      const message = error.response.data.error === 'Email ya existe' 
        ? error.response.data.error : 'Ocurrio un error lo sentimos'
      
      swal.fire({
        title: message,
        icon: "error",
      });
    }
  }

  return (
    <div className="row">
      <div className="col s12">
        <div className="card">
          <form
            className="col s12"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <div className="card-content">
              <h3 className="card-title">Nuevo usuario</h3>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    type="text"
                    autoComplete="off"
                    onChange={(e) => setname(e.target.value)}
                    value={name}
                    minLength={5}
                    maxLength={50}
                    required
                  />
                  <label>Nombre</label>
                </div>

                <div className="input-field col s12">
                  <input
                    type="email"
                    autoComplete="off"
                    onChange={(e) => setemail(e.target.value)}
                    value={email}
                    required
                  />
                  <label>Email</label>
                </div>

                <div className="input-field col s12">
                  <input
                    type="password"
                    autoComplete="off"
                    onChange={(e) => setpassword(e.target.value)}
                    value={password}
                    minLength={6}
                    maxLength={16}
                    required
                  />
                  <label>Password</label>
                </div>

                <div className="input-field col s12">
                  <select required id="rol" 
                    className="browser-default" 
                    onChange={(e) => setrol(e.target.value)}
                    >
                    {roles.map((item) => 
                      <option value={item.id} key={item.tipo}>{item.tipo.toUpperCase()}</option>
                    )})
                  </select>
                </div>

                <div className="card-action mb-4">
                  <button className="btn btn-small float-right" type="submit">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      
    </div>
  )
}

export default NewUser
