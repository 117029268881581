import React, { useEffect } from 'react'

export default function InputFilter({data, id, updFilters}) {
    useEffect(() => {
        window.$(`input#${id}`).autocomplete({
            data: data,
            onAutocomplete: (value) => {
                const type = id !== 'codsNuevo' ? id : 'cod_nuevo'
                updFilters(value, type)
                document.getElementById(id).value = '';
            }
        })
    }, [])

    useEffect(() => {
        window.$(`input#${id}`).autocomplete('updateData', data)
    }, [data, id])

    return (
        <input
          id={id}
          type="text"
          autoComplete="off"
          className="autcomplete"
        />
    );
}

